@media (min-width: 992px) {
  .layout-dashboard .ant-layout-footer {
    margin: 0 0 20px 20px;
  }
  .layout-dashboard .ant-layout-sider.sider-primary {
    margin: 0;
    padding: 13px 20px;
    height: 100vh;
  }
  .layout-dashboard .ant-layout {
    width: auto;
    flex-shrink: 1;
  }
  .layout-dashboard .header-control .sidebar-toggler {
    display: none;
  }
}

@media (min-width: 768px) {
  .layout-dashboard .ant-layout-header {
    margin: 10px 20px;
  }
  .layout-dashboard .header-control {
    margin-top: 0;
  }
  .layout-dashboard .header-control .header-search {
    margin: 0 7px;
  }
  .layout-dashboard .header-control .btn-sign-in span {
    display: inline;
  }
  .profile-nav-bg {
    margin-top: -87.8px;
  }
  .card-profile-head {
    margin: -53px 24px 24px;
  }
}

@media (min-width: 992px) {
  .card-billing-info.ant-card .ant-card-body {
    display: flex;
  }
  .layout-dashboard-rtl {
    overflow: auto;
  }
  .layout-dashboard-rtl .ant-layout-sider.sider-primary {
    margin: 20px 20px 0 0;
    height: calc(100vh - 20px);
  }
  .layout-dashboard-rtl .ant-layout-sider.sider-primary {
    right: 0;
    left: auto;
  }
  .layout-dashboard-rtl .ant-layout-sider.sider-primary {
    right: 0;
    left: auto;
  }
  .layout-dashboard-rtl .ant-layout {
    margin-right: 270px;
    margin-left: 0;
  }
  .layout-dashboard-rtl .ant-layout-footer {
    margin: 0 20px 20px 0;
  }
}

/* CSS for Responsive Form */
/* Small screens (phones) */
@media screen and (max-width: 576px) {
  /* Adjust the form width and card width for small screens */
  .ant-card {
    width: 90%;
  }

  /* Center-align the card and reduce top margin */
  .ant-card,
  .ant-row {
    margin-top: 20px;
  }

  /* Increase the font size for smaller screens */
  .ant-form-item-label {
    font-size: 14px;
  }

  /* Reduce input field width for small screens */
  .ant-input {
    width: 100%;
  }

  /* Reduce button width for small screens */
  .ant-btn {
    width: 100%;
  }
}

/* Medium screens (tablets) */
@media screen and (min-width: 375px) and (max-width: 991px) {
  /* Adjust the form width and card width for medium screens */
  .ant-card {
    width: 80%;
  }

  /* Center-align the card and reduce top margin */
  .ant-card,
  .ant-row {
    margin-top: 30px;
  }

  /* Increase the font size for medium screens */
  .ant-form-item-label {
    font-size: 16px;
  }

  /* Reduce input field width for medium screens */
  .ant-input {
    width: 100%;
  }

  /* Reduce button width for medium screens */
  .ant-btn {
    width: 100%;
  }
  .signed-in a,
  .signed-in .keep {
    font-size: 11px !important;
  }
}

/* Large screens (desktops) */
@media screen and (max-width: 992px) {
  /* Adjust the form width and card width for large screens */
  .ant-card {
    width: 600px;
  }

  /* Center-align the card and increase top margin */
  .ant-card,
  .ant-row {
    margin-top: 24px;
  }

  /* Reset font size to default for large screens */
  .ant-form-item-label {
    font-size: inherit;
  }

  /* Reset input field width to default for large screens */
  .ant-input {
    width: 100%;
  }

  /* Reset button width to default for large screens */
  .ant-btn {
    width: auto;
  }
  .right-side {
    height: 686px !important;
  }
  .form-text h3 {
    white-space: nowrap;
  }
  .form-text p {
    margin-bottom: -10px !important;
  }
  .login-form .ant-row.ant-form-item-row {
    margin-left: -2px !important;
  }

  .login-form .ant-input-affix-wrapper {
    padding: 11px !important;
  }
  .login-form .ant-form-item-label {
    margin-bottom: 12px !important;
  }
  .form-logo {
    padding-bottom: 20px !important;
  }
  .layout-dashboard .ant-layout-header {
    margin: 0px !important;
  }
  header.ant-layout-header .ant-row {
    margin-top: 0 !important;
  }
  main.ant-layout-content.content-ant {
    margin: 0px !important;
  }
  .layout-dashboard .ant-card {
    width: 100%;
  }
  main.ant-layout-content.content-ant .ant-card-head-wrapper {
    display: block;
    margin-top: 20px;
  }
  main.ant-layout-content.content-ant .ant-card-head-wrapper .ant-btn-primary {
    margin-top: 13px;
  }
  .layout-dashboard .ant-card .ant-card-head-title {
    font-size: 28px;
  }
}

@media screen and (max-width: 390px) {
  /* Adjust the form width and card width for large screens */
  .ant-card {
    width: 300px;
  }

  /* Center-align the card and increase top margin */
  .ant-card,
  .ant-row {
    margin-top: 30px;
  }

  /* Reset font size to default for large screens */
  .ant-form-item-label {
    font-size: inherit;
  }

  /* Reset input field width to default for large screens */
  .ant-input {
    width: 100%;
  }

  /* Reset button width to default for large screens */
  .ant-btn {
    width: auto;
  }
  .form-logo {
    text-align: center;
  }
  .form-text h3 {
    text-align: center !important;
    font-size: 22px !important;
    white-space: nowrap;
  }
  .form-text p {
    font-size: 15px !important;
    text-align: center !important;
  }

  .login-form .ant-input-affix-wrapper {
    padding: 11px !important;
  }
  .login-form .ant-form-item-label {
    margin-bottom: 10px !important;
  }
  .layout-dashboard .ant-layout-header {
    margin: 0px !important;
  }
  header.ant-layout-header .ant-row {
    margin-top: 0 !important;
  }
  main.ant-layout-content.content-ant {
    margin: 0px !important;
  }
  .layout-dashboard .ant-card {
    width: 100%;
  }
  main.ant-layout-content.content-ant .ant-card-head-wrapper {
    display: block;
    margin-top: 20px;
  }
  main.ant-layout-content.content-ant .ant-card-head-wrapper .ant-btn-primary {
    margin-top: 13px;
  }
  .layout-dashboard .ant-card .ant-card-head-title {
    font-size: 28px;
  }
  .foot-btns {
    justify-content: center;
  }
  footer.ant-layout-footer {
    margin-left: 0px !important;
  }
  .ant-drawer-content-wrapper {
    width: 390px !important;
  }
  .ant-drawer-body .ant-row {
    margin: 0;
  }
  .role-text {
    display: block !important;
  }
  .role-text .ant-typography {
    word-break: unset !important;
    width: 100%;
  }
  .ant-transfer.ant-transfer-customize-list {
    display: block !important;
  }
  .ant-transfer-operation {
    justify-content: center;
    align-items: center;
  }
  .ant-col.ant-col-20.ant-col-offset-3.data-margin {
    margin-left: 0 !important;
  }
  .brand-box-inner {
    margin-right: 0 !important;
  }
  .no-wrap-head1 {
    margin-left: 0 !important;
  }
}

@media (max-width: 1600px) {
  .brand-box-inner h3 b {
    display: flex !important;
    justify-content: center !important;
    text-align: center;
  }
}

@media (max-width: 1440px) {
  .left-side {
    margin-top: 60px !important;
  }
  .right-side {
    height: 100vh !important;
    width: 60% !important;
  }
  .stats-font {
    font-size: 34px !important;
    line-height: 50px !important;
  }
  .num-1-stat {
    line-height: 0px !important;
  }
  .num-1-btn {
    margin-top: 11%;
  }
  .daily-stats .ant-card-head-title,
  .monthly-stats .ant-card-head-title {
    font-size: 19px !important;
  }
  .brand-box-inner {
    margin-right: 9px !important;
  }
  .no-wrap-head {
    white-space: unset !important;
  }
  .no-wrap-head1 {
    margin-left: 0 !important;
    white-space: unset !important;
  }
  .brand-box-inner {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 90px !important;
  }
  .brand-box-inner h3 b {
    font-size: 15px !important;
  }
  .box.brandbox .ant-card-body .ant-row {
    justify-content: center !important;
  }
}

@media (max-width: 1366px) {
  .left-side {
    margin-top: 60px !important;
  }
  .right-side {
    height: 100vh !important;
    width: 60% !important;
  }
  .stats-font {
    font-size: 25px !important;
    line-height: 50px !important;
  }
  .num-1-stat {
    line-height: 0px !important;
  }
  .num-1-btn {
    margin-top: 11%;
  }
  .monthly-stats .ant-card-head-title,
  .daily-stats .ant-card-head-title {
    font-size: 14px !important;
  }
  .monthly-stats .ant-card-extra {
    width: 78% !important;
  }
  .monthly-stats .ant-picker {
    padding: 4px 5px 4px !important;
  }
  .brand-box-inner {
    margin-right: 10px !important;
  }
  .no-wrap-head {
    white-space: unset !important;
  }
  .no-wrap-head1 {
    margin-left: 0 !important;
    white-space: unset !important;
  }
  .brand-box-inner {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex: 0 0 41.66666667% !important;
    max-width: 41.66666667% !important;
  }
  .brand-box-inner h3 b {
    font-size: 14px !important;
  }
  .ant-col.brand-box-inner h6 b {
    font-size: 12px !important;
  }
  .stats-btn {
    margin-right: -11px !important;
    padding: 4px 15px;
    margin-top: 0 !important;
  }
  .ant-card.ant-card-bordered.box.brandbox
    .ant-card-head
    .ant-card-head-wrapper
    .ant-card-head-title
    img {
    width: auto;
  }
}

@media (max-width: 1200px) {
  .left-side {
    margin-top: 60px !important;
  }
  .right-side {
    height: 100vh !important;
    width: 60% !important;
  }
  .rightwork p {
    font: normal normal normal 13px/23px Plus Jakarta Sans !important;
  }
  .rightwork .rightlogo img {
    width: 40% !important;
  }
  .stats-font {
    font-size: 20px !important;
    line-height: 50px !important;
  }
  .num-1-stat {
    line-height: 0px !important;
  }
  .num-1-btn {
    margin-top: 11%;
  }
  .monthly-stats .ant-card-head-title,
  .daily-stats .ant-card-head-title {
    font-size: 15px !important;
  }
  .monthly-stats .ant-card-extra {
    width: 74% !important;
  }
  .monthly-stats .ant-picker {
    padding: 4px 2px 4px !important;
    margin-right: 4px !important;
  }
  .monthly-stats .icon-stat {
    margin-right: -12px !important;
  }
  .monthly-stats .ant-picker-input > input {
    font-size: 12px !important;
    font-weight: 500 !important;
  }
  .monthly-stats button.ant-btn.ant-btn-primary {
    height: 29px;
    padding: 4px 10px;
    font-size: 13px;
  }
  .brand-box-inner {
    margin-right: 9px !important;
  }
  .no-wrap-head {
    white-space: unset !important;
  }
  .no-wrap-head1 {
    margin-left: 0 !important;
    white-space: unset !important;
  }
  .brand-box-inner {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .brand-box-inner h3 b {
    font-size: 14px !important;
  }
  .stats-btn {
    margin-left: 20px;
    font-size: 12px;
    margin-top: 5px;
    margin-right: -4px;
  }
  .role-txt {
    font-size: 18px !important;
  }
}

@media (max-width: 1100px) {
  .left-side {
    margin-top: 60px !important;
  }
  .right-side {
    height: 100vh !important;
    width: 60% !important;
  }
  .rightwork p {
    font: normal normal 600 12px/23px Plus Jakarta Sans !important;
  }
  .rightwork .rightlogo img {
    width: 33% !important;
  }
  .stats-font {
    font-size: 20px !important;
    line-height: 50px !important;
  }
  .num-1-stat {
    line-height: 19px !important;
  }
  .num-1-btn {
    margin-top: 11%;
  }
  .icon-stat {
    margin-right: -13px !important;
    margin-top: -35px !important ;
    font-size: 30px !important;
  }
  .box strong {
    font-size: 17px !important;
  }
  .monthly-stats .ant-card-head-title,
  .daily-stats .ant-card-head-title {
    font-size: 13px !important;
    font-weight: bolder;
  }
  .monthly-stats .ant-card-extra {
    width: 74% !important;
  }
  .monthly-stats .ant-picker {
    padding: 4px 2px 4px !important;
    margin-right: 4px !important;
    width: 38% !important;
  }
  .monthly-stats .ant-picker-input > input {
    font-size: 12px !important;
    font-weight: 500 !important;
  }
  .monthly-stats button.ant-btn.ant-btn-primary {
    height: 27px;
    padding: 4px 9px;
    font-size: 12px;
    font-weight: 600;
  }
  .brand-box-inner {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .full-with {
    width: 100% !important;
  }
  .role-txt {
    font-size: 16px !important;
    margin-top: 7px !important;
  }
  .stats-btn {
    font-size: 12px;
    margin-top: 5px;
    height: 29px;
    margin-right: -11px !important;
    padding: 4px 11px;
  }
}

@media (max-width: 1024px) {
  .left-side {
    margin-top: 48px !important;
    width: 50%;
  }
  .right-side {
    height: 100vh !important;
    width: 55% !important;
  }
  .rightwork p {
    font: normal normal 700 11px/23px Plus Jakarta Sans !important;
    margin-bottom: -30px !important;
  }
  .rightwork .rightlogo img {
    width: 40% !important;
    margin-bottom: -20px !important;
  }
  .stats-font {
    font-size: 19px !important;
    line-height: 20px !important;
  }
  .stats-font-2 {
    line-height: 50px !important;
  }
  .num-1-stat {
    line-height: 20px !important;
  }
  .num-2-btn {
    margin-top: 11% !important ;
  }
  .num-3-btn {
    margin-top: 0% !important;
  }
  .icon-stat {
    margin-right: -13px !important;
    margin-top: -35px !important ;
    font-size: 30px !important;
  }
  .box strong {
    font-size: 15px !important;
  }
  .monthly-stats .ant-card-head-title,
  .daily-stats .ant-card-head-title {
    font-size: 11px !important;
    font-weight: bolder;
  }
  .monthly-stats .ant-card-extra {
    width: 75% !important;
  }
  .monthly-stats .ant-picker {
    padding: 4px 2px 4px !important;
    margin-right: 4px !important;
    width: 35% !important;
  }
  .monthly-stats .ant-picker-input > input {
    font-size: 12px !important;
    font-weight: 500 !important;
  }
  .monthly-stats button.ant-btn.ant-btn-primary {
    height: 27px;
    padding: 4px 9px;
    font-size: 12px;
    font-weight: 600;
  }
  .stats-btn {
    margin-top: -7px;
    padding: 6px;
    height: 27px;
    margin-right: -21px !important;
    font-size: 10px;
    margin-left: 8px;
  }
  .full-with {
    width: 100% !important;
  }
  .role-txt {
    font-size: 15px !important;
    margin-top: 6px;
  }
}

@media (max-width: 992px) {
  .left-side {
    margin-top: 80px !important;
    width: 50%;
  }
  .right-side {
    height: 100vh !important;
    width: 55% !important;
  }
  .rightwork p {
    font: normal normal 300 11px/23px Plus Jakarta Sans !important;
    margin-bottom: -30px !important;
  }
  .rightwork .rightlogo img {
    width: 38% !important;
    margin-bottom: -20px !important;
  }
  .stats-font {
    font-size: 25px !important;
    line-height: 50px !important;
  }
  .stats-font-2 {
    line-height: 50px !important;
  }
  .num-1-stat {
    line-height: 50px !important;
  }
  .num-1-btn {
    margin-top: 1% !important;
  }
  .num-2-btn {
    margin-top: 2% !important ;
  }
  .num-3-btn {
    margin-top: 0% !important;
  }
  .icon-stat {
    margin-right: -13px !important;
    margin-top: -35px !important ;
    font-size: 34px !important;
  }
  .box strong {
    font-size: 18px !important;
  }
  .monthly-stats .ant-card-head-title,
  .daily-stats .ant-card-head-title {
    font-size: 16px !important;
    font-weight: bolder;
    margin-bottom: -10px;
  }
  .monthly-stats .ant-card-extra {
    width: 74% !important;
  }
  .monthly-stats .ant-picker {
    padding: 4px 7px 4px !important;
    margin-right: 13px !important;
    width: 38% !important;
  }
  .monthly-stats .ant-picker-input > input {
    font-size: 12px !important;
    font-weight: 500 !important;
  }
  .monthly-stats button.ant-btn.ant-btn-primary {
    height: 27px;
    padding: 4px 9px;
    font-size: 12px;
    font-weight: 600;
  }
  .monthly-stats .ant-card-head .ant-card-head-wrapper {
    display: flex !important;
    margin-top: 0 !important;
  }
  .monthly-stats .ant-card-body {
    height: 436px !important;
  }
  .stats-btn {
    font-size: 13px;
    padding: 10px;
    justify-content: center;
    height: 36px;
    margin-right: 1px !important;
  }
  .brand-box-inner {
    margin-right: 0px !important;
  }
  .brand-box .ant-card-head .ant-card-head-wrapper {
    text-align: center;
  }
  .ant-col.brand-box-inner h6 b {
    font-size: 13px !important;
  }
  .full-with {
    width: 100% !important;
  }
  .role-txt {
    font-size: 17px !important;
  }
}

@media (max-width: 768px) {
  .left-side {
    width: 100% !important;
  }
  .right-side {
    display: none !important;
  }
  .stats-font {
    font-size: 23px !important;
    line-height: 20px !important;
  }
  .stats-font-2 {
    line-height: 60px !important;
  }
  .num-1-stat {
    line-height: 20px !important;
  }
  .num-1-btn {
    margin-top: 10% !important;
  }
  .num-2-btn {
    margin-top: 10% !important ;
  }
  .num-3-btn {
    margin-top: -3% !important;
  }
  .icon-stat {
    margin-right: -13px !important;
    margin-top: -35px !important ;
    font-size: 34px !important;
  }
  .box strong {
    font-size: 16px !important;
  }
  .monthly-stats .ant-card-head-title,
  .daily-stats .ant-card-head-title {
    font-size: 13px !important;
    font-weight: bolder;
  }
  .monthly-stats .ant-card-extra {
    width: 70% !important;
  }
  .monthly-stats .ant-picker {
    padding: 4px 2px 4px !important;
    margin-right: 4px !important;
    width: 35% !important;
  }
  .monthly-stats .ant-picker-input > input {
    font-size: 12px !important;
    font-weight: 500 !important;
  }
  .monthly-stats button.ant-btn.ant-btn-primary {
    height: 27px;
    padding: 4px 9px;
    font-size: 12px;
    font-weight: 600;
  }
  .role-txt {
    font-size: 16px !important;
  }
}

@media (max-width: 576px) {
  .left-side {
    width: 100% !important;
  }
  .right-side {
    display: none !important;
  }
  .stats-font {
    font-size: 35px !important;
    line-height: 50px !important;
  }
  .stats-font-2 {
    line-height: 60px !important;
  }
  .num-1-stat {
    line-height: 60px !important;
  }
  .num-1-btn {
    margin-top: -2% !important;
  }
  .num-2-btn {
    margin-top: 0% !important ;
  }
  .num-3-btn {
    margin-top: -3% !important;
  }
  .icon-stat {
    margin-right: -3px !important;
    margin-top: -35px !important ;
    font-size: 40px !important;
  }
  .box strong {
    font-size: 18px !important;
  }
  .monthly-stats .ant-card-head-title,
  .daily-stats .ant-card-head-title {
    font-size: 18px !important;
    font-weight: bolder;
  }
  .monthly-stats .ant-card-extra {
    width: 73% !important;
  }
  .monthly-stats .ant-picker {
    padding: 4px 8px 4px !important;
    margin-right: 11px !important;
    width: 40% !important;
  }
  .monthly-stats .ant-picker-input > input {
    font-size: 13px !important;
    font-weight: 500 !important;
  }
  .monthly-stats button.ant-btn.ant-btn-primary {
    height: 29px;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 600;
  }
  .main-row-target {
    justify-content: center;
  }
  .stats-btn {
    margin-left: -2px !important;
  }
  .main-row-target .main-card {
    display: block;
    flex: 0 0 49.666667%;
    max-width: 49.666667%;
  }
  .full-with .ant-card .ant-card-body .ant-row .ant-col.ant-col-8 {
    padding-left: 10px !important;
    padding-right: 10px !important;
    text-align: center !important;
  }
  .role-txt {
    font-size: 17px !important;
    margin-top: -4px !important;
  }
  .ant-transfer.ant-transfer-customize-list {
    justify-content: end !important;
  }
}

@media (max-width: 450px) {
  .stats-font {
    font-size: 35px !important;
    line-height: 50px !important;
  }
  .stats-font-2 {
    line-height: 60px !important;
  }
  .num-1-stat {
    line-height: 60px !important;
  }
  .num-1-btn {
    margin-top: -2% !important;
  }
  .num-2-btn {
    margin-top: 0% !important ;
  }
  .num-3-btn {
    margin-top: -3% !important;
  }
  .icon-stat {
    margin-right: -3px !important;
    margin-top: -35px !important ;
    font-size: 40px !important;
  }
  .box strong {
    font-size: 18px !important;
  }
  .monthly-stats .ant-card-head-title,
  .daily-stats .ant-card-head-title {
    font-size: 14px !important;
    font-weight: bolder;
  }
  .monthly-stats .ant-card-extra {
    width: 73% !important;
  }
  .monthly-stats .ant-picker {
    padding: 4px 8px 4px !important;
    margin-right: 6px !important;
    width: 38% !important;
  }
  .monthly-stats .ant-picker-input > input {
    font-size: 13px !important;
    font-weight: 500 !important;
  }
  .monthly-stats button.ant-btn.ant-btn-primary {
    height: 29px;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 600;
  }
  .main-row-target .main-card {
    flex: 0 0 49.666667%;
    max-width: 49.666667%;
  }
  .role-txt {
    font-size: 15px !important;
  }
  .ant-transfer.ant-transfer-customize-list {
    justify-content: end !important;
  }
  .ant-transfer-customize-list .ant-transfer-list {
    width: 52% !important;
  }
  .justi-center {
    justify-content: center !important;
  }
  .justi-inner {
    display: flex;
    justify-content: center;
    margin-left: 0;
  }
}

@media (max-width: 390px) {
  .left-side {
    width: 100% !important;
  }
  .right-side {
    display: none !important;
  }
  .monthly-stats .ant-card-head-title,
  .daily-stats .ant-card-head-title {
    font-size: 14px !important;
    font-weight: bolder;
  }
  .monthly-stats .ant-card-extra {
    width: 70% !important;
  }
  .monthly-stats .ant-picker {
    padding: 4px 8px 4px !important;
    margin-right: 4px !important;
    width: 35% !important;
  }
  .monthly-stats .ant-picker-input > input {
    font-size: 13px !important;
    font-weight: 500 !important;
  }
  .monthly-stats button.ant-btn.ant-btn-primary {
    height: 29px;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 600;
  }
  .main-row-target .main-card {
    flex: 0 0 49.666667%;
    max-width: 49.666667%;
  }
  .ant-transfer-customize-list .ant-transfer-list {
    width: 100% !important;
  }
  .justi {
    width: 100% !important;
    justify-content: center;
    display: flex;
  }
}
