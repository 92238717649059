@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700;800&display=swap");

.containers {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: hidden;
}

.left-side {
  padding: 0 80px;
  width: 40%;
  flex-wrap: wrap;
  align-items: center;
  margin: 106px 0 0;
}

.right-side {
  width: 60%;
  height: 100vh;
}
.form-logo {
  padding-bottom: 30px;
}

.login-form button.ant-btn-primary {
  background: transparent linear-gradient(96deg, #3bc29cd7 0%, #00916c 100%) 0% 0%
    no-repeat padding-box;
  width: 100%;
  color: #fff;
  font-size: 22px;
  height: auto !important;
  padding: 18px 79px 17px;
  cursor: pointer;
  transition: 0.5s ease-in-out;
  text-transform: capitalize;
  border-radius: 6px;
  margin-top: 30px;
  font-family: proxy;
  border: 0;
}

.login-form .ant-form-item-control {
  margin-left: 0 !important;
}

.signed-in input[type="checkbox"] {
  margin-right: 10px;
  border: 2px dotted #00f;
}

.signed-in .keep {
  text-align: left;
  font: normal normal 12px/15px Plus Jakarta Sans;
  letter-spacing: 0px;
  color: #acacac;
  opacity: 1;
}

.signed-in a {
  text-align: right;
  font: normal normal 600 12px/15px Plus Jakarta Sans;
  letter-spacing: 0px;
  color: #0499fd;
  opacity: 1;
  text-decoration: none;
}

.form-text h3 {
  text-align: left;
  font: normal normal 800 24px/30px Plus Jakarta Sans;
  letter-spacing: 0px;
  color: #171725;
  opacity: 1;
}

.form-text p {
  text-align: left;
  font: normal normal normal 14px/21px Plus Jakarta Sans;
  letter-spacing: 0px;
  color: #a5a5a5;
  margin-top: 20px;
  margin-bottom: 40px;
}

.form-text p a {
  color: #0499fd;
}

.login-form input#basic_email {
  margin-top: -1px;
}

.login-form .ant-row.ant-form-item-row {
  display: block !important;
}
.login-form input#basic_password {
  border-radius: 10px 0 0 10px;
}

.login-form input#basic_email {
  height: 60px !important;
  margin-bottom: -3px;
  border: 1px solid #ccc;
  width: 100%;
  border-radius: 10px;
}
.login-form .ant-form-item-label {
  text-align: left !important;
  text-align: left;
  font: normal normal 600 12px/15px Plus Jakarta Sans;
  letter-spacing: 0px;
  color: #171725;
  opacity: 1;
  margin-bottom: 20px;
}

.login-form label.ant-form-item-required::after,
.login-form label.ant-form-item-required::before {
  content: "" !important;
}

.login-form .ant-input-affix-wrapper > .ant-input:not(textarea) {
  height: 100%;
  padding-left: 12px;
}

.login-form .ant-input-affix-wrapper {
  padding: 0 !important;
}

.login-form input#basic_email {
  height: 60px !important;
  margin-bottom: -3px;
  border: 1px solid #ccc;
  width: 100%;
  border-radius: 10px;
}
.login-form span.ant-input-password {
  height: 60px !important;
  margin-bottom: -3px;
  border: 1px solid #ccc;
  width: 100%;
  border-radius: 10px;
  background-color: #e8f0fe;
}

.login-form input::placeholder {
  color: #000;
  font: normal normal 600 14px/18px Plus Jakarta Sans;
}
.login-form span.ant-input-suffix {
  padding-right: 11px;
  margin-left: 0 !important;
  font-size: 18px;
}

.signup-details {
  margin-top: -5px;
  width: 100%;
}

.signup-details a {
  font-size: 18px;
  text-transform: capitalize;
  text-decoration: underline;
  color: #000;
  font-weight: 400;
  font-size: 19px;
  font-family: "Alata", sans-serif;
  letter-spacing: 0.1px;
}

.signup-details span {
  font-size: 20px;
  font-family: proxy;
}

.right-side {
  background-image: url(/src/assets/Group\ 7819.svg);
  display: flex;
  justify-content: end;
  align-items: end;
  background-size: cover;
}

.right-side .rightwork {
  margin: 0 50px 50px 0;
  width: 59%;
  display: inline-block;
}

.rightwork p {
  text-align: right;
  font: normal normal normal 14px/23px Plus Jakarta Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-top: 26px;
}

.right-side .rightwork .rightlogo {
  text-align: right;
}

.login-form .ant-form-item-control-input-content img {
  position: absolute;
  z-index: 9;
  top: 21px;
  left: 3%;
}
