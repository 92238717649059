@import url(https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700;800&display=swap);
/* @import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); */

body {
  padding: 0 0 30px 0;
  background-color: #fafafa;
  letter-spacing: -0.3px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.6;
  font-weight: 400;
  font-family: Plus Jakarta Sans !important;
}
.ant-layout.ant-layout-has-sider {
  flex-direction: row;
}
.layout-dashboard {
  background: transparent;
  text-align: left;
  position: relative;
  overflow-x: hidden;
}
@media (min-width: 992px) {
  .layout-dashboard {
    overflow: auto;
  }
}
.layout-dashboard .ant-layout-sider.sider-primary {
  width: 250px;
  position: fixed;
  left: 0;
  z-index: 99;
  height: 100vh;
  overflow: auto;
  margin: 0;
  padding: 33px 20px;
  background-color: #000 !important;
}
.layout-dashboard .ant-layout-sider.sider-primary .brand img {
  height: 50px;
}
.layout-dashboard .ant-layout-sider.sider-primary hr {
  margin: 18px 0;
  border: none;
  height: 1px;
  background-color: #f5f5f5;
}
.layout-dashboard .ant-layout-sider.sider-primary .brand {
  font-weight: 600;
  margin: 19px 0;
}
.layout-dashboard .ant-layout-sider.sider-primary .brand span {
  vertical-align: middle;
  margin-left: 3px;
}

.layout-dashboard .ant-layout-sider.sider-primary .ant-layout-sider-children {
  width: auto;
}

.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-inline {
  border: none;
  background-color: black;
}
.layout-dashboard .ant-menu {
  background: transparent;
}

.ant-menu span.ant-menu-title-content {
  color: #fff !important;
}

span.label.sidenav-text {
  color: #fff !important;
}

.layout-dashboard
  .ant-layout-sider.sider-primary
  .ant-menu-inline
  .ant-menu-item,
.layout-dashboard
  .ant-layout-sider.sider-primary
  .ant-menu-inline
  .ant-menu-submenu {
  margin: 0;
  overflow: visible;
}

.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu {
  padding: 0 !important;
  height: auto;
  line-height: normal;
}

.layout-dashboard
  .ant-layout-sider.sider-primary
  .ant-menu-inline
  .ant-menu-item:after,
.layout-dashboard
  .ant-layout-sider.sider-primary
  .ant-menu-inline
  .ant-menu-submenu:after {
  display: none;
}

.layout-dashboard
  .ant-layout-sider.sider-primary
  .ant-menu-item.ant-menu-item-selected,
.layout-dashboard
  .ant-layout-sider.sider-primary
  .ant-menu-item.ant-menu-item:active,
.layout-dashboard
  .ant-layout-sider.sider-primary
  .ant-menu-item
  .ant-menu-submenu-selected,
.layout-dashboard
  .ant-layout-sider.sider-primary
  .ant-menu-item.ant-menu-submenu-title:active,
.layout-dashboard
  .ant-layout-sider.sider-primary
  .ant-menu-item
  .ant-menu-submenu:active,
.layout-dashboard
  .ant-layout-sider.sider-primary
  .ant-menu-submenu.ant-menu-item-selected,
.layout-dashboard
  .ant-layout-sider.sider-primary
  .ant-menu-submenu.ant-menu-item:active,
.layout-dashboard
  .ant-layout-sider.sider-primary
  .ant-menu-submenu
  .ant-menu-submenu-selected,
.layout-dashboard
  .ant-layout-sider.sider-primary
  .ant-menu-submenu.ant-menu-submenu-title:active,
.layout-dashboard
  .ant-layout-sider.sider-primary
  .ant-menu-submenu
  .ant-menu-submenu:active {
  background-color: transparent;
}

.layout-dashboard
  .ant-layout-sider.sider-primary
  .ant-menu-item.ant-menu-item-selected
  .active,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item .active,
.layout-dashboard
  .ant-layout-sider.sider-primary
  .ant-menu-submenu.ant-menu-item-selected
  .active,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu .active {
  background-color: #fff;
  box-shadow: 0 20px 27px rgb(0 0 0 / 5%);
  font-weight: 600;
}

a.sidenav-back.active span.label.sidenav-text {
  color: #000 !important;
}

.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item a,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu a {
  padding: 14px 16px ;
  color: #000000;
  border-radius: 8px;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.layout-dashboard
  .ant-layout-sider.sider-primary.ant-layout-sider-primary
  .ant-menu-item.ant-menu-item-selected
  .active
  .icon,
.layout-dashboard
  .ant-layout-sider.sider-primary.ant-layout-sider-primary
  .ant-menu-item
  .active
  .icon {
  background-color: #1890ff;
}
.layout-dashboard
  .ant-layout-sider.sider-primary
  .ant-menu-item.ant-menu-item-selected
  .active
  .icon,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item .active .icon,
.layout-dashboard
  .ant-layout-sider.sider-primary
  .ant-menu-submenu.ant-menu-item-selected
  .active
  .icon,
.layout-dashboard
  .ant-layout-sider.sider-primary
  .ant-menu-submenu
  .active
  .icon {
  background-color: #00916c;
}

.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item .icon,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu .icon {
  display: inline-flex;
  width: 32px;
  height: 32px;
  background: linear-gradient(
    153deg,
    rgb(12, 116, 3) 0%,
    rgb(0, 134, 40) 100%
  );
  box-shadow: 0 4px 6px rgb(0 0 0 / 12%);
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  margin-right: 11px;
  vertical-align: middle;
}

.layout-dashboard
  .ant-layout-sider.sider-primary
  .ant-menu-item.ant-menu-item-selected
  .active
  svg
  path,
.layout-dashboard
  .ant-layout-sider.sider-primary
  .ant-menu-item
  .active
  svg
  path,
.layout-dashboard
  .ant-layout-sider.sider-primary
  .ant-menu-submenu.ant-menu-item-selected
  .active
  svg
  path,
.layout-dashboard
  .ant-layout-sider.sider-primary
  .ant-menu-submenu
  .active
  svg
  path {
  fill: #fff;
}
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item svg path,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu svg path {
  fill: #bfbfbf;
}

.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-item a:hover,
.layout-dashboard .ant-layout-sider.sider-primary .ant-menu-submenu a:hover {
  color: #141414;
}

.layout-dashboard
  .ant-layout-sider.sider-primary
  .ant-menu-inline
  .ant-menu-item.menu-item-header {
  padding: 10px 16px !important;
  color: #8c8c8c;
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
  display: block;
}

.menu-item-header:hover {
  color: inherit;
}

.layout-dashboard .ant-layout-sider.sider-primary.ant-layout-sider-zero-width {
  overflow-x: hidden;
  width: 210px;
  margin: 0;
  padding: 33px 0;
}

.layout-dashboard.has-sidebar .ant-layout-sider.sider-primary .aside-footer {
  display: block;
}
.layout-dashboard
  .ant-layout-sider.sider-primary.ant-layout-sider-primary
  .footer-box {
  background-color: #1890ff;
  color: #fff;
  box-shadow: none;
}
.layout-dashboard .ant-layout-sider.sider-primary .footer-box {
  background-color: #fff;
  color: #141414;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 20px 27px rgb(0 0 0 / 5%);
  margin-bottom: 8px;
}

.layout-dashboard
  .ant-layout-sider.sider-primary.ant-layout-sider-primary
  .footer-box
  .icon {
  background-color: #fff;
}
.layout-dashboard .ant-layout-sider.sider-primary .footer-box .icon {
  display: inline-flex;
  width: 32px;
  height: 32px;
  box-shadow: 0 4px 6px rgb(0 0 0 / 12%);
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  background-color: #1890ff;
}

.layout-dashboard
  .ant-layout-sider.sider-primary.ant-layout-sider-primary
  .footer-box
  h6 {
  color: #fff;
}
.layout-dashboard .ant-layout-sider.sider-primary .footer-box h6 {
  font-weight: 600;
  font-size: 16px;
  color: #141414;
  margin-bottom: 0;
}
.layout-dashboard
  .ant-layout-sider.sider-primary.ant-layout-sider-primary
  .footer-box
  p {
  color: #f5f5f5;
}
.layout-dashboard .ant-layout-sider.sider-primary .footer-box p {
  color: #8c8c8c;
  font-weight: 600;
}

.layout-dashboard
  .ant-layout-sider.sider-primary.ant-layout-sider-primary
  .footer-box
  button {
  background-color: #fff;
  border-color: #f0f0f0;
  color: #141414;
}

.layout-dashboard .ant-layout-sider.sider-primary .footer-box button {
  margin: 0;
}
.ant-btn-sm,
.ant-radio-group-small .ant-radio-button-wrapper {
  height: 34px;
  font-size: 12px;
  padding: 0 15px;
}
.ant-btn-sm {
  border-radius: 4px;
}

.ant-btn-sm,
.ant-radio-group-small .ant-radio-button-wrapper {
  height: 34px;
  font-size: 12px;
  padding: 0 15px;
}
.ant-btn-sm {
  border-radius: 4px;
}
.ant-btn,
.ant-radio-group .ant-radio-button-wrapper {
  font-weight: 600;
}

.layout-dashboard .ant-layout-sider.sider-primary .aside-footer {
  padding-top: 100px;
  padding-bottom: 33px;
}
.ant-menu-inline.ant-menu-root .ant-menu-item,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
  display: block;
}

.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  overflow-x: hidden;
}
.layout-dashboard .ant-layout {
  background: transparent;
  position: relative;
  min-height: 100vh;
  width: 100%;
  flex-shrink: 0;
}

.layout-dashboard .ant-layout-header {
  background: #fff;
  height: auto;
  padding: 16px;
  line-height: inherit;
  border-radius: 0 !important;
  transition: 0.2s;
  margin: 0px 0px 10px 250px !important;
  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.08);
}

.layout-dashboard .ant-breadcrumb > span .ant-breadcrumb-link a {
  color: #8c8c8c;
}
.layout-dashboard .ant-breadcrumb > span:last-child .ant-breadcrumb-link {
  color: #141414;
}
.layout-dashboard .ant-page-header-heading {
  margin-top: 0;
}
.layout-dashboard .ant-page-header-heading .ant-page-header-heading-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin-top: 4px;
}
.layout-dashboard .header-control {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-top: 10px;
  justify-content: space-between;
}

span.ant-dropdown-trigger {
  width: 10%;
  text-align: right;
}

.ant-dropdown-menu.ant-dropdown-menu-split.header-notifications-dropdown {
  background: #fff;
  box-shadow: 0 20px 27px rgb(0 0 0 / 5%);
  border-radius: 12px;
  min-width: 276px;
  padding: 16px 8px;
}

.ant-dropdown-menu.ant-dropdown-menu-split.header-notifications-dropdown
  ul.ant-dropdown-menu-items {
  margin: 0px;
  padding: 0px;
}

.ant-dropdown.ant-dropdown-placement-bottomRight {
  min-width: inherit !important;
}

.ant-dropdown-menu.ant-dropdown-menu-split.header-notifications-dropdown
  .ant-list-item {
  padding: 5px 16px;
  margin-bottom: 8px;
}
.ant-dropdown-menu.ant-dropdown-menu-split.header-notifications-dropdown
  .ant-avatar {
  width: 36px;
  height: 36px;
  background-color: #fafafa;
  box-shadow: 0 4px 6px rgb(0 0 0 / 12%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-dropdown-menu.ant-dropdown-menu-split.header-notifications-dropdown
  .ant-list-item-meta-title {
  line-height: normal;
}
.ant-dropdown-menu.ant-dropdown-menu-split.header-notifications-dropdownt
  .ant-list-item-meta-description {
  color: #8c8c8c;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
}
.ant-dropdown-menu.ant-dropdown-menu-split.header-notifications-dropdown
  .ant-list-item-meta-description
  svg {
  width: 14px;
  height: 14px;
  fill: #8c8c8c;
  vertical-align: middle;
  margin-right: 4px;
}
.ant-dropdown-menu.ant-dropdown-menu-split.header-notifications-dropdown
  .ant-list-item-meta-description
  svg
  path {
  fill: #8c8c8c;
}
.ant-dropdown-menu.ant-dropdown-menu-split.header-notifications-dropdown
  .ant-list-item-meta-description
  span {
  vertical-align: middle;
}

.ant-dropdown-menu.ant-dropdown-menu-split.header-notifications-dropdown
  .ant-avatar
  .ant-avatar-string {
  top: 6px;
}

.ant-dropdown-menu.ant-dropdown-menu-split.header-notifications-dropdown
  .ant-avatar
  .ant-avatar-string
  svg {
  width: 22px;
  height: 22px;
}

.h3,
.h4,
h3,
h4 {
  font-weight: 700;
  color: #141414;
}

.ant-list-item-meta-title > a {
  color: rgba(0, 0, 0, 0.65);
  transition: all 0.3s;
}

.ant-list-item-meta-title {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 22px;
}

.layout-dashboard .header-control .ant-btn-link {
  height: auto;
  padding: 0 7px;
  margin: 0;
  box-shadow: none;
  color: #8c8c8c;
  line-height: normal;
}

.ant-btn svg {
  margin-right: 5px;
}
.layout-dashboard .header-control svg {
  width: 20px;
  height: 20px;
}

.layout-dashboard .header-control svg path {
  fill: #141414;
}

.layout-dashboard .header-control .btn-sign-in {
  height: auto;
  padding: 0 7px;
  margin: 0;
  box-shadow: none;
  color: #8c8c8c;
}
.layout-dashboard .header-control .btn-sign-in svg {
  margin-right: 4px;
}
.layout-dashboard .header-control svg {
  vertical-align: middle;
}

.layout-dashboard .header-control .header-search {
  width: 213px;
  margin: 0 7px 0 0;
  border-radius: 6px;
  height: 40px;
  border: 1px solid #d9d9d9;
}

.layout-dashboard .header-control .anticon.anticon-search svg {
  width: 16px;
  height: 16px;
}
.layout-dashboard .header-control .header-search svg path {
  fill: #8c8c8c;
}

.settings-drawer .drawer-content > hr {
  margin: 18px 0;
  border: none;
  height: 1px;
  background-color: #f5f5f5;
}

.settings-drawer .drawer-content > h6 {
  font-size: 20px;
  margin-bottom: 0;
}
.layout-dashboard .settings-drawer .drawer-content h6 {
  font-weight: 600;
  color: #141414;
}
.settings-drawer .drawer-content p {
  color: #8c8c8c;
}
p {
  font-size: 14px;
}
.settings-drawer .drawer-content .sidebar-color h6 {
  margin-bottom: 8px;
}

.h6,
h6 {
  font-size: 16px;
}

.drawer-sidebar .ant-drawer-body {
  padding: 0px;
}

.drawer-sidebar
  .ant-drawer-body
  aside.ant-layout-sider.ant-layout-sider-light.sider-primary.ant-layout-sider-primary {
  margin: 0px;
  background-color: #000 !important;
}

.layout-dashboard
  .header-control
  .header-search
  .ant-input-suffix
  .ant-input-search-icon.anticon-loading,
.layout-dashboard .header-control .sidebar-toggler {
  display: block;
}
.layout-dashboard .header-control .btn-sign-in span {
  font-weight: 600;
  vertical-align: middle;
  display: none;
}

/* Old css start */
.site-layout-background {
  background: transparent;
}

header.ant-layout-header.subheader {
  background: transparent;
}

.sidemenu {
  margin-top: 20px;
  margin-left: 20px;
}

.logo img {
  width: 32px;
  margin-right: 10px;
}

.logo {
  margin-bottom: 15px;
}
.logo span {
  font-size: 16px;
  font-weight: 600;
}

hr.horizontal.dark {
  background-image: linear-gradient(
    90deg,
    transparent,
    rgb(0 0 0 / 12%),
    transparent
  );
  border: 0px;
}
hr.horizontal {
  background-color: transparent;
}
hr:not([size]) {
  height: 1px;
}

ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.left-nav {
  background: transparent;
}

ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.left-nav li {
  padding: 10px 16px !important;
  height: auto;
  line-height: normal;
  margin: 0px;
  border: 0px;
  font-size: 16px;
  color: #141414;
}

ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.left-nav
  li.ant-menu-item-selected {
  box-shadow: 0px 20px 27px #0000000d;
  border-radius: 8px;
  background: #fff;
  border: 0px;
  font-weight: 600;
}
ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.left-nav
  li.ant-menu-item-selected {
  box-shadow: 0px 20px 27px #0000000d;
  border-radius: 8px;
  background: #fff;
  border: 0px;
  font-weight: 600;
}
.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content,
.ant-menu-inline.ant-menu-root
  .ant-menu-submenu-title
  > .ant-menu-title-content {
  flex: auto;
  min-width: 0;
  overflow: visible;
  text-overflow: ellipsis;
}

ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.left-nav li::after {
  display: none;
}

ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.left-nav
  li
  span.anticon {
  width: 32px;
  height: 32px;
  line-height: 32px;
  background: #fff;
  box-shadow: 0px 4px 6px #0000001f;
  border-radius: 6px;
  font-size: 16px;
}

ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.left-nav
  li.ant-menu-item-selected
  span.anticon {
  background: #1890ff;
  color: #fff;
}

/* ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.left-nav li:hover {
  box-shadow: 0px 20px 27px #0000000d;
  border-radius: 8px;
  background: #fff;
  border: 0px;
  font-weight: 600;
}

ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.left-nav
  li:hover
  span.anticon {
  background: #1890ff;
  color: #fff;
} */

.divder {
  display: table;
  width: 100%;
  padding: 10px 15px;
  color: #8c8c8c;
  font-weight: 600;
  margin: 15px 0px;
}

.ant-card.ant-card-bordered.documentation-card {
  border: 0px;
  box-shadow: 0px 20px 27px #0000000d;
  border-radius: 8px;
  background: #000000;
}
.p-15 {
  padding: 15px;
}
.card-document {
  width: 32px;
  height: 32px;
  text-align: center;
  box-shadow: 0px 4px 6px #0000001f;
  border-radius: 6px;
  font-size: 19px;
  background: #1890ff;
  color: #fff;
  direction: ltr;
  margin-bottom: 15px;
}
.documentation-card h4 {
  margin: 0px;
}
.left-nav {
  /* height: 435px;
  overflow: hidden;
  overflow-y: auto;
  padding-right: 15px; */

  margin-top: 15px;
}
.ant-layout {
  background: #fafafa;
}
.pageheader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rightside-top {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: normal;
}

.signin {
  white-space: nowrap;
  margin: 0px 20px;
}

.signin span.ant-avatar {
  margin-right: 9px;
}

.shadow-lg {
  box-shadow: 0 8px 26px -4px hsla(0, 0%, 8%, 0.15),
    0 8px 9px -5px hsla(0, 0%, 8%, 0.06) !important;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border: 0px;
}
.header-top h4 {
  margin: 0px;
  font-size: 20px;
}

.header-top .subtitle {
  display: block;
  font-weight: normal;
  color: #8c8c8c;
  font-size: 14px;
}

.header-top {
  margin-top: -10px;
}

.ant-drawer.ant-drawer-right.ant-drawer-open.colorside button.ant-drawer-close {
  color: #000;
}
.header-top {
  border-bottom: 1px solid rgb(140 140 140 / 7%);
  padding-bottom: 15px;
}

.rightside-top span.ant-input-affix-wrapper {
  border-radius: 4px;
}

.rightside-top span.ant-input-affix-wrapper span.ant-input-prefix {
  color: #c0c0c0;
}

.setting button.ant-btn {
  padding: 0px;
  line-height: normal;
  background: transparent;
  border: 0px;
  box-shadow: none;
}
button.ant-btn.bell {
  padding: 0px;
  border: 0px;
  background: transparent;
  box-shadow: none;
  position: relative;
}
button.ant-btn.bell strong {
  position: absolute;
  top: -1px;
  right: -5px;
  display: inline-block;
  width: 20px;
  height: 20px;
  background: #f5222d;
  border-radius: 100%;
  text-align: center;
  line-height: normal;
  font-weight: normal;
  color: #fff;
}
.ant-dropdown-menu.ant-dropdown-menu-split.list-notifaction {
  width: 336px;
}

.list-notifaction ul {
  padding: 5px 10px;
  margin: 0px;
}

.list-notifaction ul li {
  display: block;
  width: 100%;

  clear: both;
  font-weight: 400;
  color: #67748e;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.list-notifaction ul li a {
  display: block;
  padding: 10px;
  border-radius: 8px;
}

.list-notifaction ul li a:hover {
  color: #252f40;
  background-color: #e9ecef;
}

.list-notifaction ul span.ant-avatar.ant-avatar-image {
  width: 48px;
  height: 48px;
  border-radius: 0.75rem;
}

.list-notifaction ul li {
  padding: 0px;
}
.ant-dropdown-menu.ant-dropdown-menu-split.list-notifaction {
  border-radius: 0.75rem;
}
.list-notifaction .ant-list-item-meta-title {
  font-weight: bold;
  color: #141414;
}
.list-notifaction .ant-list-item-meta-title {
  font-weight: bold;
  color: #141414;
}

.list-notifaction .ant-avatar.ant-avatar-circle {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.list-notifaction ul li a span.ant-avatar-string {
  line-height: normal;
}
.bnb2 {
  color: #52c41a;
  font-weight: 700;
}
.payment-method-card .ant-card.criclebox {
  box-shadow: 0px 20px 27px #0000000d;
  border-radius: 12px;
  background: #3368e4;
}
.payment-method-card .ant-card.criclebox1 {
  box-shadow: 0px 20px 27px #0000000d;
  border-radius: 12px;
  background: #004c70;
}
.payment-method-card .ant-card.criclebox2 {
  box-shadow: 0px 20px 27px #0000000d;
  border-radius: 12px;
  background: #27a100;
}
.payment-method-card .ant-card.criclebox3 {
  box-shadow: 0px 20px 27px #0000000d;
  border-radius: 12px;
  background: #d00;
}

.payment-method-card .ant-card.criclebox4 {
  box-shadow: 0px 20px 27px #0000000d;
  border-radius: 12px;
  background: rgba(206, 221, 0, 0.719);
}
/* .ant-col.payment-method-card:nth-child(2) .ant-card.criclebox {
  background: #004c70;
}

.ant-col.payment-method-card:nth-child(3) .ant-card.criclebox {
  background: #27a100;
}

.ant-col.payment-method-card:nth-child(4) .ant-card.criclebox {
  background: #d00;
}

.ant-col.payment-method-card:nth-child(5) .ant-card.criclebox {
  background: rgba(206, 221, 0, 0.719);
} */

.payment-method-card .ant-card-body {
  padding: 40px 50px;
}

.payment-method-card .ant-card.criclebox:hover {
  background: linear-gradient(189deg, #3368e4 0, #57b5fa 50%);
  transition: 0.5s ease-in-out;
}
.number span {
  color: #ffffff;
  font-size: 25px;
  padding: 0px;
  font-weight: 900;
}
.number h3 {
  font-weight: 700;
  margin-bottom: 0px;
  font-size: 30px;
  color: #ffff;

  text-align: center;
}
.view-box {
  text-align: right;
}

.view-box button {
  background: #000;
  padding: 8px 0px;
  height: auto;
  border: 0;
  width: 167px;
}

.view-box button span {
  font-size: 23px;
  font-weight: 400;
  color: white;
}
.ant-card-body .ant-typography {
  position: relative;
}

.number h3 small {
  font-weight: 600;
  font-size: 14px;
}
.icon-box {
  width: 40px;
  height: 40px;
  text-align: center;
  background: #000;
  color: #fff;
  border-radius: 0.5rem;
  margin-left: auto;
  line-height: 36px;
}

.icon-box span {
  color: #fff;
  font-size: 24px;
}

.listpage {
  padding-top: 15px;
}
.bar-chart {
  background: transparent
    linear-gradient(62deg, #00369e 0%, #005cfd 53%, #a18dff 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 0px 4px 6px #0000001f;

  border-radius: 8px;
}

.chart-vistior {
  margin-top: 30px;
}

.chart-vistior h5 {
  margin: 0px;
  font-weight: 700;
}
.ant-typography.lastweek {
  color: #8c8c8c;
  font-weight: 600;
}

.chart-visitor-count h4 {
  margin: 0px;
  font-weight: 700;
}

.chart-visitor-count span {
  color: rgba(0, 0, 0, 0.65);
  font-weight: 600;
}

.linechart h5 {
  font-weight: 700;
  margin: 0px;
}
.linechart {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sales ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.sales ul li:first-child span.anticon {
  color: #b37feb;
}

.sales ul li:last-child span.anticon {
  color: #1890ff;
}
.sales ul li {
  color: #8c8c8c;
  margin-bottom: 3px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
}
.project-ant h4 {
  margin: 0px;
  font-size: 16px;
  font-weight: bold;
  color: #141414;
}

span.blue {
  margin-left: 5px;
  color: #1890ff;
  font-weight: 600;
}
.project-ant h5 {
  margin: 0px;
  color: #141414;
  font-weight: 700;
}

span.blue {
  margin-left: 5px;
  color: #1890ff;
  font-weight: 600;
}
.project-ant {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.antd-pro-pages-dashboard-analysis-style-salesExtra a {
  display: inline-block;
  padding: 7px 10px;
  color: #595959;
  border: 1px solid #d9d9d9;
  text-align: center;
  border-right: 1px solid transparent;
}

.antd-pro-pages-dashboard-analysis-style-salesExtra a:last-child {
  border-right: 1px solid #d9d9d9;
  border-radius: 0px 5px 5px 0px;
}

.antd-pro-pages-dashboard-analysis-style-salesExtra a:first-child {
  border-radius: 5px 0px 0px 5px;
}

.antd-pro-pages-dashboard-analysis-style-salesExtra a:hover {
  color: #1890ff;
  border: 1px solid #1890ff;
}
.antd-pro-pages-dashboard-analysis-style-salesExtra a.active {
  color: #1890ff;
  border: 1px solid #1890ff;
}

.ant-list-box th {
  text-align: left;
  border-bottom: 1px solid #0000000d;
  padding: 8px 0px;
  color: #8c8c8c;
  font-size: 12px;
  font-weight: 600;
}

.ant-list-box td {
  text-align: left;
  border-bottom: 1px solid #0000000d;
  padding: 16px 25px;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 600;
}
.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}
.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}
.d-flex {
  display: flex !important;
}
.justify-content-center {
  justify-content: center !important;
}
.flex-column {
  flex-direction: column !important;
}
.me-3 {
  margin-right: 1rem !important;
}
.tootip-img {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  border: 1px solid #fff;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

.avatar-group .tootip-img + .tootip-img {
  margin-left: -10px;
}

.tootip-img:hover {
  z-index: 3;
  cursor: pointer;
}
.title-box h5 {
  margin: 0px;
}

.text-xs {
  color: #8c8c8c;
}

.percent-progress span.ant-progress-text {
  display: flex;
  margin-top: -27px;
  color: #8c8c8c;
  position: relative;
  margin-left: 0px;
}
/* .percent-progress .ant-progress-outer {
  margin-right: 0px;
  padding-right: 0px;
} */

.percent-progress .ant-progress-status-exception .ant-progress-text {
  color: #ff4d4f;
}
.ant-full-box {
  width: 100%;
  border: 1px dashed #d9d9d9;
  padding: 10px;
  height: auto;
  color: #141414;
  border-radius: 10px;
}
.uploadfile {
  margin-top: 15px;
}

.uploadfile .ant-upload.ant-upload-select {
  display: block;
}
.ant-timeline-item-tail {
  left: 7px;
}

.ant-list-box h5 {
  font-size: 14px;
}
.timeline-box h5 {
  margin-bottom: 0px;
  font-weight: 700;
}
.timelinelist h5 {
  font-size: 14px;
  font-weight: 600;
}

.timelinelist span {
  color: #8c8c8c;
  font-size: 12px;
}
.ant-muse span {
  color: #8c8c8c;
  font-weight: 600;
}

.ant-muse h5 {
  margin: 0px 0px 15px !important;
  font-size: 20px;
}
.bg-gradient-primary {
  background-image: linear-gradient(310deg, #2152ff, #21d4fd);
}
img {
  max-width: 100%;
}
.border10 {
  border-radius: 10px;
}
.py-4 {
  padding: 20px;
}
.mb-5 {
  margin-bottom: 3rem !important;
}
.icon-move-right .anticon-right {
  color: #40a9ff;
  margin-left: 2px;
}
.icon-move-right {
  color: #1890ff;

  display: block;
}
.mb-4 {
  margin-bottom: 1.5rem !important;
}

.gradent {
  /* background: url("../images/info-card-2.jpg") no-repeat center top; */
  background-size: cover;
  padding: 15px;
  border-radius: 15px;
  color: #fff;
}

.gradent h5 {
  color: #fff;
}

.gradent .ant-typography {
  color: #fff;
  font-size: 20px;
}

.gradent .icon-move-right {
  color: #fff;
}

.gradent .icon-move-right .anticon-right {
  color: #fff;
}
.copyright {
  color: #8c8c8c;
  margin-top: 7px;
}
.copyright .anticon-heart {
  color: #f5222d;
  margin: 0px 5px;
}
.copyright a {
  margin: 0px 5px;
  color: #141414;
  font-weight: 700;
}
.footer-menu ul {
  margin: 0px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.footer-menu ul li {
  list-style: none;
}
.footer-menu ul li a {
  padding: 0.5rem 1rem;
  display: block;
  color: #8c8c8c;
}
span.ismobile {
  display: none;
  margin-right: 15px;
  cursor: pointer;
}
@media (max-width: 992px) {
  .footer-menu ul {
    justify-content: center;
  }
  footer.ant-layout-footer {
    padding: 0px 15px;
    text-align: center;
  }
  .project-ant {
    display: block;
  }

  .ant-filtertabs {
    text-align: right;
  }
  .rightside-top span.ant-input-affix-wrapper {
    width: 200px;
  }

  .rightside-top {
    justify-content: flex-end;
  }

  header.ant-layout-header.subheader {
    padding: 0px 24px 0px 0px;
  }

  main.ant-layout-content.content-ant {
    padding-left: 0px !important;
  }

  .ant-row {
    margin-left: -12px;
    margin-right: -12px;
  }
  span.ismobile {
    display: inline-block;
  }
  .bar-chart {
    width: 100% !important;
    max-width: 100%;
  }
  .full-width {
    max-width: 100% !important;
  }
  .bar-chart {
    width: 100% !important;
  }

  .icon-move-right {
    margin-bottom: 15px;
  }
  .pageheader {
    display: block;
  }
  .ant-cret img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
}

.sidebar-color {
  padding: 1.5rem 0px;
}
.theme-color button {
  margin-right: 8px;
  border: 0px;
  width: 20px !important;
  height: 20px !important;
  padding: 0px;
  box-shadow: none;
  visibility: visible !important;
  line-height: 20px !important;
}

.theme-color button:focus {
  box-shadow: none;
  border-color: transparent;
}

.theme-color button span {
  opacity: 0;
}

.mb-2 {
  margin-bottom: 25px;
}

.sidebarnav-color button {
  width: 100%;
  margin-right: 10px;
  box-shadow: 0 3px 5px -1px rgb(0 0 0 / 9%), 0 2px 3px -1px rgb(0 0 0 / 7%);
  height: 40px;
}

.sidebarnav-color h5 {
  margin: 0px;
}

.sidebarnav-color span.ant-typography {
  color: #8c8c8c;
}

.sidebarnav-color .trans {
  margin-top: 1rem;
  display: flex;
}

.sidebarnav-color button:last-child {
  margin-right: 0px;
}

.ant-docment button {
  width: 100%;
  margin-bottom: 10px;
  border-radius: 5px;
}

.viewstar {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}

.viewstar a {
  display: inline-block;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 16px;
  border-radius: 0em 0.25em 0.25em 0em;
  border: 1px solid #ddddde;
  border-left: 0px;
  color: #141414;
}

.viewstar a:first-child {
  color: #24292e;
  background-color: #eff3f6;
  border-color: #cfd3d6;
  background-image: linear-gradient(180deg, #fafbfc, #eff3f6 90%);
  border-radius: 0.25em 0 0 0.25em;
  border-left: 1px solid #ddddde;
}

.social {
  text-align: center;
  margin-top: 25px;
}

.social button {
  margin: 0px 5px;
  display: inline-flex;
  align-items: center;
}

.ant-thank {
  text-align: center;
  margin-top: 25px;
}
.setting-drwer {
  background: #fff;
  border-radius: 50%;
  bottom: 42px;
  right: 30px;
  font-size: 1.25rem;
  z-index: 990;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 16%);
  width: 52px;
  height: 52px;
  text-align: center;
  line-height: 60px;
  position: fixed;
  cursor: pointer;
}

ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.left-nav li:active {
  background: transparent;
}
.rightside-top .ant-input {
  height: 30px;
}
.aside-footer {
  padding-top: 100px;
}
.ant-card-body {
  padding: 16px;
}

ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.left-nav li svg path {
  color: #bfbfbf;
}

.cardbody .ant-card-body {
  padding-left: 0px;
  padding-right: 0px;
}

.ant-card.criclebox .project-ant {
  padding-left: 24px;
  padding-right: 24px;
}

.ant-card.criclebox table th {
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 14px;
  padding-bottom: 14px;
}
.percent-progress .ant-progress-bg {
  height: 3px !important;
}
.uploadfile {
  padding-left: 24px;
  padding-right: 24px;
}
.ant-card.tablespace .ant-card-body {
  padding: 0px;
}
.tablespace .ant-card-head-title {
  font-size: 20px;
  font-weight: 600;
}
.ant-radio-button-wrapper:first-child {
  border-radius: 4px 0 0 4px;
}
.ant-radio-button-wrapper:last-child {
  border-radius: 0 4px 4px 0;
}
ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.left-nav
  li.ant-menu-item-selected
  path {
  color: #fff;
}
.ant-avatar-group .ant-avatar-square {
  border-radius: 4px;
}

.shape-avatar {
  margin-right: 20px;
}

.shape-avatar img {
  border-radius: 4px;
}

.avatar-info h5 {
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 3px;
}

.avatar-info p {
  font-size: 14px;
  font-weight: 400;
  color: #8c8c8c;
  margin: 0px;
}
.ant-card > ant-card-bordered > criclebox {
}
.ant-table-tbody > tr > td h5 {
  font-size: 14px;
  font-weight: 600;
  color: #141414;
  margin-bottom: 0px;
}
.ant-table-tbody > tr > td p {
  font-size: 14px;
  color: #8c8c8c;
  margin: 0px;
}

.tag-primary {
  border-radius: 6px;
  padding: 2px 12px;
  font-size: 14px;
  height: auto;
  line-height: 24px;
  font-weight: 600;
}

.ant-employed {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  color: #8c8c8c;
}

.ant-employed a {
  color: #8c8c8c;
  text-decoration: none;
  font-weight: 600;
}

.tag-badge {
  border-radius: 6px;
  padding: 2px 12px;
  font-size: 14px;
  height: auto;
  line-height: 24px;
  font-weight: 600;
  background: #8c8c8c;
  color: #fff;
  outline: none;
}

.tag-badge:hover {
  background: #8c8c8c;
  color: #fff;
  border-color: #8c8c8c;
  opacity: 0.9;
}

.ant-avatar-group {
  align-items: center;
}

.mb-24 {
  margin-bottom: 24px;
}

.ant-table-thead > tr > th {
  color: #8c8c8c;
  font-weight: 700;
  background-color: transparent;
  font-size: 12px;
}

.ant-border-space th:before {
  display: none;
}
.ant-table-tbody > tr > td,
.ant-table-thead > tr > th {
  padding: 16px 25px;
}
.tablespace {
  overflow: hidden;
}
.ant-table.ant-table-middle .ant-table-title,
.ant-table.ant-table-middle .ant-table-footer,
.ant-table.ant-table-middle .ant-table-thead > tr > th,
.ant-table.ant-table-middle .ant-table-tbody > tr > td,
.ant-table.ant-table-middle tfoot > tr > th,
.ant-table.ant-table-middle tfoot > tr > td {
  padding: 16px 25px;
}

.avatar-sm {
  width: 25px;
}

.ant-progress-project {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ant-progress-project .ant-progress-outer {
  float: right;
}

.ant-progress-project .ant-progress-text {
  margin: 0px 0px 0px 0px;
  color: #8c8c8c;
  font-weight: 600;
}
.semibold {
  font-weight: 600;
  color: #8c8c8c;
}

.text-sm {
  font-size: 12px;
  color: #8c8c8c;
  font-weight: 600;
}
.ant-progress-project .ant-progress {
  margin-right: 15px;
}

.semibold {
  font-weight: 600;
  color: #8c8c8c;
}

.text-sm {
  font-size: 12px;
  color: #8c8c8c;
  font-weight: 600;
}

.ant-progress-project .ant-progress-bg {
  height: 3px !important;
}
.ant-table-tbody > tr > td,
.ant-table-thead > tr > th {
  white-space: nowrap;
  font-size: 14px;
}
.h-full {
  height: 100%;
}
@media (min-width: 1200px) {
  .ant-col.col-img img {
    height: 196px;
  }
  /* .bar-chart {
    max-width: 438px;
  } */
  /* .full-width {
    max-width: 658px;
  } */
}
@media (max-width: 1024px) {
  .bar-chart {
    max-width: 100%;
  }
  .full-width {
    max-width: 100%;
  }
  /* .ant-col.mobile-24 {
    margin-bottom: 24px;
  } */
}

body {
  overflow: visible !important;
  width: 100% !important;
}

.card-info-2 .ant-card-body {
  height: 100%;
}

.col-content {
  flex-grow: 1;
  padding: 24px;
  margin-right: 0;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.card-content p {
  font-size: 16px;
}

.ant-layout-footer {
  padding: 0px 50px;
}
/* sign up start */
.layout-default.ant-layout.layout-sign-up header.ant-layout-header {
  background-color: transparent;
  color: #fff;
  margin: 0;
  padding: 0 20px;
  z-index: 1;
}

.layout-default .ant-layout-header {
  display: flex;
}

.header-col.header-nav {
  margin-left: auto;
  width: 100%;
  margin-right: auto;
}
.header-col.header-nav .ant-menu-overflow {
  justify-content: center;
}

.header-col.header-nav .ant-menu-horizontal {
  background: transparent;
  box-shadow: none;
  border: 0px;
}

.layout-default.ant-layout.layout-sign-up {
  padding: 11px;
}
.header-col.header-nav li:after {
  display: none;
}
.sign-up-header {
  height: 550px;
  margin-top: -62.8px;
  padding-top: 137.8px;
  border-radius: 12px;
  box-shadow: 0 20px 27px rgb(0 0 0 / 5%);
  background-size: cover;
  background-position: 50%;
  text-align: center;
  /* background: url("../images/bg-signup.jpg") no-repeat center top; */
}
.header-col.header-brand {
  white-space: nowrap;
}

.header-col.header-brand h5 {
  margin: 0px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}

.header-col.header-nav {
  color: #fff;
  font-weight: 600;
}

.header-col.header-nav ul li {
  color: #fff !important;
}

.header-col.header-nav ul li img {
  margin-top: -2px;
  margin-right: 5px;
}
.header-col.header-btn button {
  height: 34px;
  padding: 0 16px;
  border-radius: 34px;
  line-height: 33px;
}
.sign-up-header .content {
  padding-top: 40px;
  max-width: 480px;
  margin: auto;
}
.sign-up-header * {
  color: #000000;
}
.sign-up-header .content h1 {
  font-size: 48px;
  color: #000000;
}
.text-lg {
  font-size: 16px;
}
.card-signup .sign-up-gateways .ant-btn {
  margin-right: 10px;
  margin-left: 10px;
  height: 60px;
  width: 70px;
  box-shadow: none;
  border-radius: 6px;
}
.card-signup {
  width: 100%;
  max-width: 500px;
  margin: auto;
  box-shadow: 0 20px 27px rgb(0 0 0 / 5%);
  border-radius: 12px;
  margin-top: -190px;
  margin-bottom: 20px;
}
.card-signup h5 {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: #141414;
  margin: 0px;
}
.header-solid .ant-card-head {
  border-bottom: 0;
}
.card-signup .sign-up-gateways .ant-btn img {
  width: 20px;
}
.sign-up-gateways {
  text-align: center;
}
.m-25,
.mb-25,
.my-25 {
  margin-bottom: 25px;
}
.m-25,
.mt-25,
.my-25 {
  margin-top: 25px;
}
.text-center {
  text-align: center;
}
.font-semibold {
  font-weight: 600;
}
.text-muted {
  color: #8c8c8c;
}
.ant-input {
  border-radius: 6px;
}
.ant-input {
  font-weight: 600;
  color: #8c8c8c;
}
.ant-input {
  height: 40px;
  border: 1px solid #d9d9d9;
}
.font-bold {
  font-weight: 700;
}
.text-dark {
  color: #141414;
}
.ant-btn,
.ant-radio-group .ant-radio-button-wrapper {
  font-weight: 600;
  font-size: 12px;
  height: 40px;
  padding: 0 15px;
  line-height: 40px;
}
.ant-btn {
  box-shadow: 0 2px 4px rgb(0 0 0 / 7%);
  border-radius: 6px;
}
.layout-sign-up .ant-layout-footer {
  padding: 24px 50px;
}

.layout-default .ant-layout-footer {
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  background: transparent;
  text-align: center;
}
.layout-default .ant-layout-footer .ant-menu-horizontal {
  border: none;
  line-height: 1.5;
  margin: 0 0 30px;
  background-color: transparent;
  font-size: 16px;
}
.layout-default .ant-layout-footer .ant-menu-horizontal {
  text-align: center;
  display: block;
}

.layout-default .ant-layout-footer .ant-menu-horizontal > .ant-menu-item,
.layout-default .ant-layout-footer .ant-menu-horizontal > .ant-menu-item-active,
.layout-default
  .ant-layout-footer
  .ant-menu-horizontal
  > .ant-menu-item-selected,
.layout-default .ant-layout-footer .ant-menu-horizontal > .ant-menu-item:hover,
.layout-default .ant-layout-footer .ant-menu-horizontal > .ant-menu-submenu,
.layout-default
  .ant-layout-footer
  .ant-menu-horizontal
  > .ant-menu-submenu-active,
.layout-default
  .ant-layout-footer
  .ant-menu-horizontal
  > .ant-menu-submenu-selected,
.layout-default
  .ant-layout-footer
  .ant-menu-horizontal
  > .ant-menu-submenu:hover {
  color: #8c8c8c;
  border: none;
}
.layout-default .ant-layout-footer .ant-menu-horizontal > .ant-menu-item-active,
.layout-default
  .ant-layout-footer
  .ant-menu-horizontal
  > .ant-menu-item-selected,
.layout-default .ant-layout-footer .ant-menu-horizontal > .ant-menu-item:hover,
.layout-default
  .ant-layout-footer
  .ant-menu-horizontal
  > .ant-menu-submenu-active,
.layout-default
  .ant-layout-footer
  .ant-menu-horizontal
  > .ant-menu-submenu-selected,
.layout-default
  .ant-layout-footer
  .ant-menu-horizontal
  > .ant-menu-submenu:hover {
  color: #262626;
}
.layout-default.ant-layout.layout-sign-up .ant-layout-footer ul li:after {
  display: none;
}
.layout-default.ant-layout.layout-sign-up footer.ant-layout-footer ul li a svg {
  width: 18px;
  height: 18px;
}
.layout-default .ant-layout-footer .menu-nav-social a svg {
  fill: #8c8c8c;
  vertical-align: middle;
}
.layout-default .ant-layout-footer .menu-nav-social a:hover svg {
  fill: #1890ff;
}
.layout-default .ant-layout-footer .copyright a {
  color: inherit;
  font-weight: normal;
}
/* signin start csss */
.layout-default.layout-signin header.ant-layout-header {
  display: flex;
  position: relative;
  background-color: #fff;
  color: #141414;
  box-shadow: 0 20px 27px rgb(0 0 0 / 5%);
  flex-direction: row;
  padding: 0 50px;
  height: 64px;
  line-height: 64px;
}
.fill-muted {
  fill: #8c8c8c;
}
.layout-default .ant-layout-header .header-brand h5 {
  white-space: nowrap;

  color: #141414;
  margin: 0;
}
.layout-default .ant-layout-header .header-nav a svg {
  margin-right: 5px;
}
.layout-default .ant-layout-header .header-nav a span,
.layout-default .ant-layout-header .header-nav a svg {
  vertical-align: middle;
}
.layout-default .ant-layout-header .header-nav a {
  color: #141414;
  padding: 6px 12px;
  font-weight: 400;
}

.layout-default
  .ant-layout-header
  .header-nav
  .ant-menu-horizontal
  > .ant-menu-item,
.layout-default
  .ant-layout-header
  .header-nav
  .ant-menu-horizontal
  > .ant-menu-item-active,
.layout-default
  .ant-layout-header
  .header-nav
  .ant-menu-horizontal
  > .ant-menu-item-selected,
.layout-default
  .ant-layout-header
  .header-nav
  .ant-menu-horizontal
  > .ant-menu-item:hover,
.layout-default
  .ant-layout-header
  .header-nav
  .ant-menu-horizontal
  > .ant-menu-submenu,
.layout-default
  .ant-layout-header
  .header-nav
  .ant-menu-horizontal
  > .ant-menu-submenu-active,
.layout-default
  .ant-layout-header
  .header-nav
  .ant-menu-horizontal
  > .ant-menu-submenu-selected,
.layout-default
  .ant-layout-header
  .header-nav
  .ant-menu-horizontal
  > .ant-menu-submenu:hover {
  color: #8c8c8c;
  font-weight: 600;
  border: none;
}
.layout-default
  .ant-layout-header
  .header-nav
  .ant-menu-horizontal
  > .ant-menu-item {
  padding: 0;
}
.sign-in {
  padding: 0 20px;
}
.layout-default .ant-layout-content {
  padding-top: 40px;
}
.font-regular {
  font-weight: 400;
}
.signin h1 {
  font-size: 48px;
  font-weight: 700;
  color: #141414;
}

.signin h5 {
  font-weight: 400;
  color: #8c8c8c;
  font-size: 20px;
  white-space: normal;
}
.ant-row.ant-form-item.username {
  display: block;
}

.ant-row.ant-form-item.username
  .ant-col.ant-form-item-label
  .ant-form-item-required:before {
  display: none;
}

.ant-row.ant-form-item.username .ant-col.ant-form-item-label {
  font-size: 13px;
  font-weight: 600;
  color: #141414;
}
.signin .ant-form-item-control-input button.ant-switch {
  margin-right: 5px;
}
.text-dark {
  color: #141414;
}
.sign-img img {
  width: 100%;
  max-width: 500px;
  margin: auto;
  display: block;
}
.layout-signin {
  background: #fff;
}
.layout-signin footer.ant-layout-footer svg {
  width: 18px;
  height: 18px;
}

.layout-signin footer.ant-layout-footer ul li:after {
  display: none;
}
.layout-default .ant-layout-footer .copyright {
  font-size: 16px;
  color: #8c8c8c;
}

.row-col .ant-row {
  margin-left: 0px;
  margin-right: 0px;
}
.layout-default.ant-layout.layout-sign-up .header-col.header-brand h5 {
  color: #fff;
}

.layout-default.ant-layout.layout-sign-up .header-col.header-nav a {
  color: #fff;
}

.layout-default.ant-layout.layout-sign-up
  .header-col.header-nav
  svg
  path.fill-muted {
  fill: #fff;
}
/* sign in end */

@media (min-width: 768px) {
  .sign-img img {
    margin: 0;
  }
  .card-signup {
    margin-bottom: 120px;
  }
  .card-signup .sign-up-gateways .ant-btn {
    width: 100px;
  }
}

/* end sign up */

.rightside-top .signin {
  display: inline-flex;
}

.rightside-top .signin svg {
  margin-right: 5px;
}

button.ant-btn.bell strong {
  position: absolute;
  top: -1px;
  right: -9px;
  display: inline-block;
  width: 18px;
  height: 18px;
  background: #f5222d;
  border-radius: 100%;
  text-align: center;
  line-height: normal;
  font-weight: normal;
  color: #fff;
}
/* old css end */

footer.ant-layout-footer {
  margin-left: 270px !important;
}

.layout-dashboard .ant-layout-footer {
  background: none !important;
  margin: 0 20px 20px 20px;
  padding: 0;
}

.layout-dashboard .ant-layout-content {
  padding: 0;
  margin: 0 20px 0;
}
.m-10,
.mr-10,
.mx-10 {
  margin-right: 10px !important;
}

.ant-table-tbody > tr > td h6 {
  font-size: 14px;
  font-weight: 600;
  color: #141414;
}

.layout-dashboard .header-control .header-search input.ant-input {
  height: auto;
}

small.redtext {
  color: red;
}

.full-width path {
  fill: transparent;
}

.bar-chart line.apexcharts-xaxis-tick {
  stroke: transparent;
}

main.ant-layout-content.content-ant {
  margin-left: 270px;
}

.ant-layout-header .ant-row .ant-col.ant-col-24.ant-col-md-6 {
  display: none;
}

section.ant-layout.layout-content .ant-layout-content.content-ant {
  padding: 0px !important;
}

.layout-dashboard .ant-card {
  border-radius: 12px;
  box-shadow: 0 20px 27px rgb(0 0 0 / 5%);
}

.card-credit {
  /* background-image: url("../images/info-card-3.jpg"); */
  background-position: 50%;
  background-size: cover;
  border: none;
}
.card-credit * {
  color: #fff;
}

.card-credit .card-number {
  word-spacing: 10px;
  font-weight: 600;
  margin-bottom: 45px;
  font-size: 20px;
}
.ant-card-head-wrapper {
  min-height: 72px;
  align-items: center !important;
}
.card-credit .ant-card-body {
  padding-top: 15px;
}
.card-credit .card-footer {
  display: flex;
  align-items: flex-end;
}
.m-auto,
.ml-auto {
  margin-left: auto !important;
}
.m-30,
.mr-30,
.mx-30 {
  margin-right: 30px !important;
}
.card-credit .card-footer h6,
.card-credit .card-footer p {
  margin-bottom: 0;
}
.card-credit .col-logo img {
  max-width: 50px;
}
.card-credit .card-footer h6 {
  font-weight: 700;
}
.width-100 {
  width: 100%;
}
.p-20 {
  padding: "0px 20px 0px 0px";
}
.layout-default .ant-layout-content.p-0 {
  padding: 0px;
}
.pt {
  padding-top: 0px;
}

.widget-2 .icon {
  width: 64px;
  height: 64px;
  margin: auto;
  background-color: #1890ff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgb(0 0 0 / 12%);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
}
.widget-2 .ant-statistic-title h6 {
  font-weight: 600;
}
.widget-2 .ant-statistic-title p {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0;
}
.widget-2 .ant-statistic-content {
  /* font-family: Plus Jakarta Sans; */
  font-weight: 600;
  font-size: 20px;
  padding: 12px 16px;
  border-top: 1px solid #f5f5f5;
  text-align: center;
}
.widget-2 .ant-statistic-title {
  text-align: center;
  padding: 16px;
}

.widget-2 .ant-card-body {
  padding: 0;
}
.widget-2 .icon img {
  width: 30px;
}

.ant-row-flex-middle {
  align-items: center;
}
.d-flex {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.payment-method-card.ant-card {
  box-shadow: none;
  word-spacing: 10px;
}

.payment-method-card.ant-card img {
  width: 40px;
}
.payment-method-card.ant-card .ant-card-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.payment-method-card.ant-card .ant-btn {
  margin-right: -20px;
}

.ant-btn.ant-btn-link.ant-edit-link {
  font-weight: 600;
  font-size: 12px;
  height: 40px;
  padding: 0 15px;
  box-shadow: none;
}

.ant-btn.ant-btn-link.ant-edit-link img {
  width: 20px;
}
.payment-method-card.ant-card .ant-btn {
  margin-right: -20px;
}

.fill-gray-7 {
  fill: #8c8c8c !important;
}

.payment-method-card.ant-card h6 {
  font-weight: 600;
  margin-bottom: 0;
  color: #141414;
}
.m-0 {
  margin: 0px;
}

.ant-card.ant-card-bordered.payment-method-card .ant-card-body {
  padding-top: 16px;
}

.header-solid .ant-card-head {
  border-bottom: 0;
}
.invoice-list .ant-list-item,
.transactions-list .ant-list-item {
  padding: 11px 0;
  border-bottom: 0;
}
.ant-invoice-card .ant-card-body {
  padding-top: 0px;
}
.invoice-list .ant-list-item-meta-title,
.transactions-list .ant-list-item-meta-title {
  font-size: 14px;
  font-weight: 600;
  color: #141414;
}

.invoice-list .ant-list-item-meta-description,
.transactions-list .ant-list-item-meta-description {
  font-size: 12px;
  font-weight: 600;
  color: #8c8c8c;
}

.invoice-list .ant-list-item-action .ant-btn,
.transactions-list .ant-list-item-action .ant-btn {
  font-weight: 600;
  color: #141414;
  box-shadow: none;
}

.ant-btn > svg,
.ant-btn > svg + span {
  vertical-align: middle;
}
.invoice-list .amount,
.transactions-list .amount {
  font-size: 14px;
  font-weight: 600;
  color: #8c8c8c;
}

.card-billing-info.ant-card {
  box-shadow: none;
  background-color: #fafafa;
  border: 1px solid #f5f5f5;
}

.ant-descriptions .ant-descriptions-title {
  font-size: 14px;
  font-weight: 600;
  color: #141414;
  margin-bottom: 14px;
}

.fill-danger {
  fill: #ff4d4f;
}
.card-billing-info.ant-card .darkbtn {
  color: #8c8c8c;
}

.col-action {
  display: flex;
}

.card-billing-info .col-action button {
  height: 34px;
  font-size: 12px;
  padding: 0 15px;
  box-shadow: none;
}

.ant-descriptions .ant-descriptions-row > td,
.ant-descriptions .ant-descriptions-row > th {
  padding-bottom: 6px;
}

.ant-descriptions .ant-descriptions-item-label {
  font-size: 13px;
  font-weight: 600;
  color: #8c8c8c;
}

.ant-descriptions .ant-descriptions-item-content {
  font-size: 13px;
  font-weight: 600;
  color: #141414;
}

.ant-descriptions .ant-descriptions-title {
  font-size: 14px;
  font-weight: 600;
  color: #141414;
  margin-bottom: 14px;
}

.card-billing-info.ant-card .ant-descriptions-header {
  margin-bottom: 0px;
}

.card-header-date {
  margin-bottom: 0;
  font-weight: 700;
  color: #8c8c8c;
}

.card-header-date > * {
  vertical-align: middle;
}
.card-header-date svg {
  margin-right: 5px;
}

.transactions-list .amount {
  font-size: 16px;
  font-weight: 700;
}

.text-light-danger {
  background: #fde3cf;
  color: #f56a00;
}
.text-fill {
  color: #52c41a;
  background: #edf9e7;
}
.text-danger {
  color: #f5222d;
}
.text-success {
  color: #52c41a;
}

.transactions-list .ant-list-item-meta {
  align-items: center;
}

.text-warning {
  background: rgb(255, 252, 231);
  color: #fadb14;
}
.text-warning-b {
  font-size: 14px;
  font-weight: 600;
  color: #fadb14;
}
.ant-card.ant-list-yes .ant-list-header {
  border: 0px;
}

.ant-card.ant-list-yes .ant-list-header h6 {
  font-size: 12px;
  font-weight: 600;
  color: #8c8c8c;
  margin: 6px 0;
}

.ant-list.ant-list-split.transactions-list.ant-newest .ant-list-header {
  padding-top: 0px;
}
.profile-nav-bg {
  height: 300px;
  margin-top: -137.8px;
  border-radius: 12px;
  box-shadow: 0 20px 27px rgb(0 0 0 / 5%);
  background-size: cover;
  background-position: 50%;
}

section.ant-layout.ant-layout-has-sider.layout-dashboard.layout-profile
  span.ant-page-header-heading-title {
  color: #fff;
}

section.ant-layout.ant-layout-has-sider.layout-dashboard.layout-profile
  span.ant-breadcrumb-separator {
  color: #fff;
}

section.ant-layout.ant-layout-has-sider.layout-dashboard.layout-profile
  span.ant-breadcrumb-link {
  color: #fff;
}

section.ant-layout.ant-layout-has-sider.layout-dashboard.layout-profile
  .header-control
  svg
  path {
  fill: #fff;
}

section.ant-layout.ant-layout-has-sider.layout-dashboard.layout-profile
  .btn-sign-in {
  color: #fff;
}

.layout-profile .ant-layout-header {
  margin: 10px 20px;
}
.card-profile-head .ant-card-head {
  border-bottom: 0px;
  padding: 0px 16px;
}

.card-profile-head {
  margin: -53px 0 24px;
}

.card-profile-head .ant-card-head .ant-avatar {
  box-shadow: 0 5px 10px rgb(0 0 0 / 12%);
  border-radius: 8px;
  margin: 0;
}

.card-profile-head .ant-card-head .avatar-info {
  margin-left: 16px;
}

.ant-card-head-title p {
  font-weight: 600;
  color: #8c8c8c;
  margin-bottom: 0;
}

.card-profile-head .ant-card-head .avatar-info h4 {
  font-size: 24px;
}

.card-profile-head .ant-card-head .avatar-info h4 {
  font-size: 24px;
}

.card-profile-head .ant-radio-group .ant-radio-button-wrapper {
  height: auto;
  font-weight: 700;
}
.ant-radio-button-wrapper {
  color: #595959;
}

.layout-profile .ant-card-head {
  padding: 0 16px;
}

.settings-list {
  list-style: none;
  padding: 0;
}
.settings-list > li:first-child {
  padding-top: 0;
}
.settings-list > li {
  padding: 12px 0;
}

.settings-list > li h6 {
  font-size: 12px;
  color: #8c8c8c;
  margin: 0;
  font-weight: 700;
}
.settings-list > li span {
  margin-left: 16px;
  color: #141414;
}
.ant-btn-link {
  box-shadow: none;
}
.card-profile-information hr {
  opacity: 0.2;
}

.p-5,
.pr-5,
.px-5 {
  padding-right: 5px !important;
}
.p-5,
.pl-5,
.px-5 {
  padding-left: 5px !important;
}
.m-5,
.mr-5,
.mx-5 {
  margin-right: 5px !important;
}
.m-5,
.ml-5,
.mx-5 {
  margin-left: 5px !important;
}

.card-profile-information .ant-descriptions .ant-descriptions-row > td,
.card-profile-information .ant-descriptions .ant-descriptions-row > th {
  padding-bottom: 16px;
}

.conversations-list .ant-list-item-meta-description {
  font-size: 12px;
  font-weight: 600;
  color: #8c8c8c;
}

.conversations-list .ant-avatar {
  border-radius: 10.5px;
  box-shadow: 0 4px 6px rgb(0 0 0 / 12%);
}
.conversations-list .ant-list-item {
  padding: 14px 0;
}

.ant-card-head-title p {
  font-weight: 600;
  color: #8c8c8c;
  margin-bottom: 0;
}

.ant-card-head-title h6 {
  margin-bottom: 0;
}

.card-project.ant-card {
  box-shadow: none;
  border: none;
}

.card-project.ant-card .ant-card-cover img {
  border-radius: 8px;
  box-shadow: 0 4px 6px rgb(0 0 0 / 12%);
}

.card-project.ant-card .card-tag,
.card-project.ant-card p {
  font-weight: 600;
  color: #8c8c8c;
  margin-bottom: 5px;
}
.card-project.ant-card h5 {
  font-weight: 600;
  font-size: 20px;
}
.card-project.ant-card .card-tag,
.card-project.ant-card p {
  font-weight: 600;
  color: #8c8c8c;
  margin-bottom: 5px;
}
.card-project.ant-card p {
  margin-bottom: 0;
}
.card-project.ant-card .ant-card-body {
  padding: 16px 0 0;
}

.card-project.ant-card .card-footer {
  margin-top: 22px;
}

.avatar-chips .ant-avatar-image {
  border: 1px solid #fff;
}
.avatar-chips .ant-avatar-image:hover,
.avatar-chips .ant-space-item:hover {
  z-index: 1;
}

.projects-uploader {
  height: 100%;
  border-radius: 8px;
  border-color: #d9d9d9;
}
.projects-uploader .ant-upload {
  width: 100%;
  height: 100%;
  margin: 0;
  border-radius: 8px;
  padding: 8px;
}

.projects-uploader .ant-upload-list.ant-upload-list-picture-card {
  height: 100%;
}

.projects-uploader .ant-upload.ant-upload-select-picture-card {
  background-color: transparent;
}

.projects-uploader svg {
  width: 20px;
  height: 20px;
}

.text-right {
  text-align: right;
}

.layout-dashboard-rtl {
  background: transparent;
  text-align: right;
  direction: rtl;
  position: relative;
  overflow-x: hidden;
}

.layout-dashboard-rtl .ant-layout-sider.sider-primary .ant-menu-item .icon,
.layout-dashboard-rtl .ant-layout-sider.sider-primary .ant-menu-submenu .icon {
  margin-left: 11px;
  margin-right: 0;
}

.layout-dashboard-rtl .ant-timeline-item-tail {
  right: 6.5px;
  left: auto;
}
.layout-dashboard-rtl .ant-timeline-item-content {
  margin: 0 33px 0 0;
}

.ant-timeline-item-head {
  width: 15px;
  height: 15px;
  border-width: 3px;
}

.layout-dashboard-rtl .ant-radio-button-wrapper:first-child {
  border-right-width: 1px;
  border-radius: 0 4px 4px 0;
}
.layout-dashboard-rtl .ant-list-box td {
  text-align: right;
}

.layout-dashboard-rtl .ant-space-horizontal {
  direction: ltr;
}

.layout-dashboard-rtl .ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-right: 8px;
  margin-left: 0px;
}
.layout-dashboard-rtl .setting-drwer {
  left: 30px;
  right: auto;
}

.layout-dashboard-rtl ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light {
  text-align: right;
}

.layout-dashboard-rtl .avatar-group.mt-2 {
  direction: ltr;
}
.ant-list-box table tr:hover td {
  background: #fafafa;
}
.layout-dashboard-rtl .icon-box {
  margin-left: 0px;
  margin-right: auto;
}
.layout-dashboard-rtl .m-10,
.layout-dashboard-rtl .mr-10,
.layout-dashboard-rtl .mx-10 {
  margin-left: 10px !important;
}

.layout-dashboard-rtl .ant-progress-show-info .ant-progress-outer {
  margin-right: 0px;
  padding-right: 0px;
}

.layout-dashboard-rtl .ant-badge-count,
.layout-dashboard-rtl .ant-badge-dot,
.layout-dashboard-rtl .ant-badge .ant-scroll-number-custom-component {
  left: 0;
  right: auto;
  transform: translate(-50%, -50%);
}

.layout-dashboard-rtl .header-control .btn-sign-in svg {
  margin-left: 4px;
  margin-right: 0px;
}

.ant-drawer.drawer-sidebar.drawer-sidebar-rtl
  aside.ant-layout-sider.ant-layout-sider-light.sider-primary.ant-layout-sider-primary {
  left: auto;
  right: 0px;
  direction: rtl;
  background-color: #000 !important;
}

.layout-dashboard.layout-dashboard-rtl .header-control span.ant-badge {
  margin: 0px 7px;
}

.layout-dashboard.layout-dashboard-rtl span.ant-input-prefix {
  margin-right: 0px;
  margin-left: 4px;
}

.layout-dashboard-rtl .ant-drawer-left .ant-drawer-content-wrapper {
  left: 0;
  direction: rtl;
}

.settings-drawer.settings-drawer-rtl {
  left: 0;
  direction: rtl;
}

.settings-drawer.settings-drawer-rtl button.ant-drawer-close {
  left: 0px;
  right: auto;
  margin-right: 0px;
  margin-left: var(--scroll-bar);
  padding-left: calc(20px - var(--scroll-bar));
}

.layout-dashboard-rtl .ant-drawer-left .ant-drawer-content-wrapper {
  left: 0;
  direction: rtl;
}

.settings-drawer.settings-drawer-rtl {
  left: 0;
  direction: rtl;
}

.settings-drawer.settings-drawer-rtl button.ant-drawer-close {
  left: 0px;
  right: auto;
  margin-right: 0px;
  margin-left: var(--scroll-bar);
  padding-left: calc(20px - var(--scroll-bar));
}

.settings-drawer.settings-drawer-rtl .sidebarnav-color button {
  margin-right: 0px;
  margin-left: 10px;
}

.settings-drawer.settings-drawer-rtl .viewstar a:first-child {
  border-radius: 0em 0.25em 0.25em 0;
  border-right: 1px solid #ddddde;
}

.settings-drawer.settings-drawer-rtl .viewstar a {
  border-radius: 0.25em 0 0 0.25em;
  border: 1px solid #ddddde;
  border-right: 0px;
}

.social svg path {
  fill: #fff;
}

.settings-drawer.settings-drawer-rtl .social svg {
  margin-right: 0px;
  margin-left: 5px;
}

.social svg {
  width: 15px;
  height: 15px;
}

.layout-dashboard .ant-affix .ant-layout-header {
  background: #fff;
  box-shadow: 0 20px 27px rgb(0 0 0 / 5%);
  margin: 10px 20px;
  z-index: 10;
}

aside.ant-layout-sider.ant-layout-sider-light.sider-primary.ant-layout-sider-primary.active-route
  a.active {
  background: transparent;
  box-shadow: none;
}
.uploadfile.pb-15 {
  padding-bottom: 16px;
}

.ant-row.ant-form-item.aligin-center .ant-form-item-control-input-content {
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.65);
}

.uploadfile.shadow-none
  .ant-upload.ant-upload-select.ant-upload-select-text
  .ant-btn.ant-full-box {
  box-shadow: none;
}

.ant-btn-dashed {
  color: rgba(0, 0, 0, 0.65);
}
button.ant-btn.ant-btn-dashed.ant-full-box svg {
  width: 16px;
  height: 16px;
}

.ant-btn-dashed.active,
.ant-btn-dashed:active {
  color: #096dd9;
  background-color: #fff;
  border-color: #096dd9;
}

section.ant-layout.ant-layout-has-sider.layout-dashboard.layout-profile
  .header-control
  span.ant-input-affix-wrapper.header-search
  .anticon-search
  svg
  path {
  fill: #8c8c8c;
}
.ant-col b {
  display: inherit;
}

.table-responsive tr:nth-child(even) {
  background: #e7e7e7;
}

.table-responsive tr {
  font-size: 16px;
  font-weight: 600;
}

.table-responsive thead.ant-table-thead tr th {
  font-weight: 800;
  font-size: 14px;
  color: #000;
}

.ant-card-head-title {
  font-size: 25px;
  font-weight: bold;
}

.target__row .ant-row {
  padding: 18px 0;
  width: 100%;
  margin: 0 !important;
}

.target__row .ant-row .ant-col {
  text-align: center;
  font-size: 17px;
}
.custom-cell {
  display: block;
  padding: 8px;
}

.green {
  background-color: green;
  color: white;
}

.red {
  background-color: red;
  color: white;
}

.green-text {
  color: green;
}

.red-text {
  color: red;
}

button.ant-btn.ant-btn-default,
button.ant-btn.ant-btn-primary {
  border-radius: 7px;
}

span.anticon.anticon-arrow-left {
  background: linear-gradient(
    160deg,
    rgba(27, 67, 138, 1) 0%,
    rgb(35, 155, 253) 67%
  );
  padding: 9px;
  color: #fff;
  border-radius: 50%;
}

li.ant-menu-submenu.ant-menu-submenu-inline span.ant-menu-title-content {
  margin-left: 19px;
}

.brandbox .ant-card-body {
  padding: 20px !important;
}

li.ant-menu-item.ant-menu-item-only-child .sidenav-back img,
li.ant-menu-submenu.ant-menu-submenu-inline img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(10%) saturate(7489%)
    hue-rotate(290deg) brightness(114%) contrast(107%);
}

li.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child
  .sidenav-back
  img {
  filter: none;
}

li.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open img {
  filter: none;
}

.table-responsive tr td {
  padding: 13px !important;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
}

.table-responsive tr:nth-child(even) {
  background: transparent;
}

.table-responsive thead.ant-table-thead tr th {
  font-weight: 500;
  font-size: 15px;
  color: #000;
  padding: 12px 14px !important;
}

.brand-box-inner {
  background-color: #eef2f3;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 15px;
  border: 2px solid #d5d6d6;
  border-radius: 10px;
  margin-bottom: 16px;
  text-align: center;
}
.ant-card.ant-card-bordered.box.brandbox {
  border-radius: 12px;
  width: 100%;
  height: auto;
  font-size: 20px;
  color: white;
  box-shadow: 1px 3px 10px grey;
}
.no-wrap-head {
  color: #35d635;
  font-size: 11px;
  white-space: nowrap;
}

.no-wrap-head1 {
  color: #35d635;
  font-size: 11px;
  text-align: center;
  margin-left: 0px;
}

.stats-btn {
  background-color: #0069d9 !important;
  border-color: #0069d9 !important;
  color: white !important;
  margin-top: 17px;
  margin-right: 3px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  height: 32px;
  padding: 4px 18px;
}

.box table tbody.ant-table-tbody tr td {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  word-wrap: break-word;
  max-width: 210px;
}

a.sidenav-back.side2 {
  padding: 10px 16px !important;
}

.old-box b {
  background-color: #eef2f3;
  padding: 15px;
  border: 2px solid #d5d6d6;
  border-radius: 10px;
  margin-bottom: 16px;
  text-align: center;
}

.score-container {
  position: relative;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  padding: 50px 90px 60px 90px;
}

.score-container video {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.score-content {
  z-index: 1;
  color: white;
  position: relative;
}

.score-img {
  text-align: center;
}

.score-img img {
  width: 247px;
  height: 74px;
  margin-bottom: 30px;
}

.sco-card1 {
  background: transparent linear-gradient(220deg, #0f558d 0%, #011e3a 100%) 0%
    0% no-repeat padding-box;
  border: 2px solid #018ce8;
  border-radius: 20px 0px;
  opacity: 0.89;
}

.sco-pad {
  padding: 30px 30px 0 30px;
}

.sco-user {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sco-user img {
  width: 65px;
  height: 74px;
}

.sco-user img.userimg {
  width: 70px;
  height: 70px;
}

.sco-card1 h3 {
  text-align: left;
  font: normal normal 800 20px/29px Plus Jakarta Sans;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  opacity: 1;
  margin-top: 20px;
}

.sco-card1 p {
  text-align: left;
  font: normal normal 16px/21px Plus Jakarta Sans;
  letter-spacing: -0.48px;
  color: #018ce8;
  opacity: 1;
  margin-bottom: 20px;
}

.target-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px 30px;
  position: relative;
  z-index: 3;
}
.back-blue {
  position: relative;
}
.back-blue::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 42px;
  background-color: #000000;
  opacity: 0.46;
  z-index: 2;
}

.target-flex p {
  text-align: left;
  font: normal normal normal 14px/21px Plus Jakarta Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-bottom: 0;
}

.target-flex span {
  text-align: right;
  font: normal normal 800 16px/24px Plus Jakarta Sans;
  letter-spacing: -0.57px;
  color: #018ce8;
  opacity: 1;
}

.back-blue2 {
  background: transparent;
  opacity: 1 !important;
}

.back-blue2 span {
  color: #fff;
}

.sco-content {
  padding-bottom: 30px;
}

.score-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.sco-card1 {
  width: 260px;
  margin: 10px;
}

.table-responsive {
  overflow-y: scroll;
  /* height: 100vh; */
}
thead.ant-table-thead {
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: white !important;
}

.table-responsive::-webkit-scrollbar {
  display: none;
}

.overlay {
  background: transparent;
  pointer-events: none; /* Allows interaction with content behind the overlay */
}

@media (min-width: 992px) {
  .layout-dashboard .ant-layout-footer {
    margin: 0 0 20px 20px;
  }
  .layout-dashboard .ant-layout-sider.sider-primary {
    margin: 0;
    padding: 13px 20px;
    height: 100vh;
  }
  .layout-dashboard .ant-layout {
    width: auto;
    flex-shrink: 1;
  }
  .layout-dashboard .header-control .sidebar-toggler {
    display: none;
  }
}

@media (min-width: 768px) {
  .layout-dashboard .ant-layout-header {
    margin: 10px 20px;
  }
  .layout-dashboard .header-control {
    margin-top: 0;
  }
  .layout-dashboard .header-control .header-search {
    margin: 0 7px;
  }
  .layout-dashboard .header-control .btn-sign-in span {
    display: inline;
  }
  .profile-nav-bg {
    margin-top: -87.8px;
  }
  .card-profile-head {
    margin: -53px 24px 24px;
  }
}

@media (min-width: 992px) {
  .card-billing-info.ant-card .ant-card-body {
    display: flex;
  }
  .layout-dashboard-rtl {
    overflow: auto;
  }
  .layout-dashboard-rtl .ant-layout-sider.sider-primary {
    margin: 20px 20px 0 0;
    height: calc(100vh - 20px);
  }
  .layout-dashboard-rtl .ant-layout-sider.sider-primary {
    right: 0;
    left: auto;
  }
  .layout-dashboard-rtl .ant-layout-sider.sider-primary {
    right: 0;
    left: auto;
  }
  .layout-dashboard-rtl .ant-layout {
    margin-right: 270px;
    margin-left: 0;
  }
  .layout-dashboard-rtl .ant-layout-footer {
    margin: 0 20px 20px 0;
  }
}

/* CSS for Responsive Form */
/* Small screens (phones) */
@media screen and (max-width: 576px) {
  /* Adjust the form width and card width for small screens */
  .ant-card {
    width: 90%;
  }

  /* Center-align the card and reduce top margin */
  .ant-card,
  .ant-row {
    margin-top: 20px;
  }

  /* Increase the font size for smaller screens */
  .ant-form-item-label {
    font-size: 14px;
  }

  /* Reduce input field width for small screens */
  .ant-input {
    width: 100%;
  }

  /* Reduce button width for small screens */
  .ant-btn {
    width: 100%;
  }
}

/* Medium screens (tablets) */
@media screen and (min-width: 375px) and (max-width: 991px) {
  /* Adjust the form width and card width for medium screens */
  .ant-card {
    width: 80%;
  }

  /* Center-align the card and reduce top margin */
  .ant-card,
  .ant-row {
    margin-top: 30px;
  }

  /* Increase the font size for medium screens */
  .ant-form-item-label {
    font-size: 16px;
  }

  /* Reduce input field width for medium screens */
  .ant-input {
    width: 100%;
  }

  /* Reduce button width for medium screens */
  .ant-btn {
    width: 100%;
  }
  .signed-in a,
  .signed-in .keep {
    font-size: 11px !important;
  }
}

/* Large screens (desktops) */
@media screen and (max-width: 992px) {
  /* Adjust the form width and card width for large screens */
  .ant-card {
    width: 600px;
  }

  /* Center-align the card and increase top margin */
  .ant-card,
  .ant-row {
    margin-top: 24px;
  }

  /* Reset font size to default for large screens */
  .ant-form-item-label {
    font-size: inherit;
  }

  /* Reset input field width to default for large screens */
  .ant-input {
    width: 100%;
  }

  /* Reset button width to default for large screens */
  .ant-btn {
    width: auto;
  }
  .right-side {
    height: 686px !important;
  }
  .form-text h3 {
    white-space: nowrap;
  }
  .form-text p {
    margin-bottom: -10px !important;
  }
  .login-form .ant-row.ant-form-item-row {
    margin-left: -2px !important;
  }

  .login-form .ant-input-affix-wrapper {
    padding: 11px !important;
  }
  .login-form .ant-form-item-label {
    margin-bottom: 12px !important;
  }
  .form-logo {
    padding-bottom: 20px !important;
  }
  .layout-dashboard .ant-layout-header {
    margin: 0px !important;
  }
  header.ant-layout-header .ant-row {
    margin-top: 0 !important;
  }
  main.ant-layout-content.content-ant {
    margin: 0px !important;
  }
  .layout-dashboard .ant-card {
    width: 100%;
  }
  main.ant-layout-content.content-ant .ant-card-head-wrapper {
    display: block;
    margin-top: 20px;
  }
  main.ant-layout-content.content-ant .ant-card-head-wrapper .ant-btn-primary {
    margin-top: 13px;
  }
  .layout-dashboard .ant-card .ant-card-head-title {
    font-size: 28px;
  }
}

@media screen and (max-width: 390px) {
  /* Adjust the form width and card width for large screens */
  .ant-card {
    width: 300px;
  }

  /* Center-align the card and increase top margin */
  .ant-card,
  .ant-row {
    margin-top: 30px;
  }

  /* Reset font size to default for large screens */
  .ant-form-item-label {
    font-size: inherit;
  }

  /* Reset input field width to default for large screens */
  .ant-input {
    width: 100%;
  }

  /* Reset button width to default for large screens */
  .ant-btn {
    width: auto;
  }
  .form-logo {
    text-align: center;
  }
  .form-text h3 {
    text-align: center !important;
    font-size: 22px !important;
    white-space: nowrap;
  }
  .form-text p {
    font-size: 15px !important;
    text-align: center !important;
  }

  .login-form .ant-input-affix-wrapper {
    padding: 11px !important;
  }
  .login-form .ant-form-item-label {
    margin-bottom: 10px !important;
  }
  .layout-dashboard .ant-layout-header {
    margin: 0px !important;
  }
  header.ant-layout-header .ant-row {
    margin-top: 0 !important;
  }
  main.ant-layout-content.content-ant {
    margin: 0px !important;
  }
  .layout-dashboard .ant-card {
    width: 100%;
  }
  main.ant-layout-content.content-ant .ant-card-head-wrapper {
    display: block;
    margin-top: 20px;
  }
  main.ant-layout-content.content-ant .ant-card-head-wrapper .ant-btn-primary {
    margin-top: 13px;
  }
  .layout-dashboard .ant-card .ant-card-head-title {
    font-size: 28px;
  }
  .foot-btns {
    justify-content: center;
  }
  footer.ant-layout-footer {
    margin-left: 0px !important;
  }
  .ant-drawer-content-wrapper {
    width: 390px !important;
  }
  .ant-drawer-body .ant-row {
    margin: 0;
  }
  .role-text {
    display: block !important;
  }
  .role-text .ant-typography {
    word-break: unset !important;
    width: 100%;
  }
  .ant-transfer.ant-transfer-customize-list {
    display: block !important;
  }
  .ant-transfer-operation {
    justify-content: center;
    align-items: center;
  }
  .ant-col.ant-col-20.ant-col-offset-3.data-margin {
    margin-left: 0 !important;
  }
  .brand-box-inner {
    margin-right: 0 !important;
  }
  .no-wrap-head1 {
    margin-left: 0 !important;
  }
}

@media (max-width: 1600px) {
  .brand-box-inner h3 b {
    display: flex !important;
    justify-content: center !important;
    text-align: center;
  }
}

@media (max-width: 1440px) {
  .left-side {
    margin-top: 60px !important;
  }
  .right-side {
    height: 100vh !important;
    width: 60% !important;
  }
  .stats-font {
    font-size: 34px !important;
    line-height: 50px !important;
  }
  .num-1-stat {
    line-height: 0px !important;
  }
  .num-1-btn {
    margin-top: 11%;
  }
  .daily-stats .ant-card-head-title,
  .monthly-stats .ant-card-head-title {
    font-size: 19px !important;
  }
  .brand-box-inner {
    margin-right: 9px !important;
  }
  .no-wrap-head {
    white-space: unset !important;
  }
  .no-wrap-head1 {
    margin-left: 0 !important;
    white-space: unset !important;
  }
  .brand-box-inner {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 90px !important;
  }
  .brand-box-inner h3 b {
    font-size: 15px !important;
  }
  .box.brandbox .ant-card-body .ant-row {
    justify-content: center !important;
  }
}

@media (max-width: 1366px) {
  .left-side {
    margin-top: 60px !important;
  }
  .right-side {
    height: 100vh !important;
    width: 60% !important;
  }
  .stats-font {
    font-size: 25px !important;
    line-height: 50px !important;
  }
  .num-1-stat {
    line-height: 0px !important;
  }
  .num-1-btn {
    margin-top: 11%;
  }
  .monthly-stats .ant-card-head-title,
  .daily-stats .ant-card-head-title {
    font-size: 14px !important;
  }
  .monthly-stats .ant-card-extra {
    width: 78% !important;
  }
  .monthly-stats .ant-picker {
    padding: 4px 5px 4px !important;
  }
  .brand-box-inner {
    margin-right: 10px !important;
  }
  .no-wrap-head {
    white-space: unset !important;
  }
  .no-wrap-head1 {
    margin-left: 0 !important;
    white-space: unset !important;
  }
  .brand-box-inner {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex: 0 0 41.66666667% !important;
    max-width: 41.66666667% !important;
  }
  .brand-box-inner h3 b {
    font-size: 14px !important;
  }
  .ant-col.brand-box-inner h6 b {
    font-size: 12px !important;
  }
  .stats-btn {
    margin-right: -11px !important;
    padding: 4px 15px;
    margin-top: 0 !important;
  }
  .ant-card.ant-card-bordered.box.brandbox
    .ant-card-head
    .ant-card-head-wrapper
    .ant-card-head-title
    img {
    width: auto;
  }
}

@media (max-width: 1200px) {
  .left-side {
    margin-top: 60px !important;
  }
  .right-side {
    height: 100vh !important;
    width: 60% !important;
  }
  .rightwork p {
    font: normal normal normal 13px/23px Plus Jakarta Sans !important;
  }
  .rightwork .rightlogo img {
    width: 40% !important;
  }
  .stats-font {
    font-size: 20px !important;
    line-height: 50px !important;
  }
  .num-1-stat {
    line-height: 0px !important;
  }
  .num-1-btn {
    margin-top: 11%;
  }
  .monthly-stats .ant-card-head-title,
  .daily-stats .ant-card-head-title {
    font-size: 15px !important;
  }
  .monthly-stats .ant-card-extra {
    width: 74% !important;
  }
  .monthly-stats .ant-picker {
    padding: 4px 2px 4px !important;
    margin-right: 4px !important;
  }
  .monthly-stats .icon-stat {
    margin-right: -12px !important;
  }
  .monthly-stats .ant-picker-input > input {
    font-size: 12px !important;
    font-weight: 500 !important;
  }
  .monthly-stats button.ant-btn.ant-btn-primary {
    height: 29px;
    padding: 4px 10px;
    font-size: 13px;
  }
  .brand-box-inner {
    margin-right: 9px !important;
  }
  .no-wrap-head {
    white-space: unset !important;
  }
  .no-wrap-head1 {
    margin-left: 0 !important;
    white-space: unset !important;
  }
  .brand-box-inner {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .brand-box-inner h3 b {
    font-size: 14px !important;
  }
  .stats-btn {
    margin-left: 20px;
    font-size: 12px;
    margin-top: 5px;
    margin-right: -4px;
  }
  .role-txt {
    font-size: 18px !important;
  }
}

@media (max-width: 1100px) {
  .left-side {
    margin-top: 60px !important;
  }
  .right-side {
    height: 100vh !important;
    width: 60% !important;
  }
  .rightwork p {
    font: normal normal 600 12px/23px Plus Jakarta Sans !important;
  }
  .rightwork .rightlogo img {
    width: 33% !important;
  }
  .stats-font {
    font-size: 20px !important;
    line-height: 50px !important;
  }
  .num-1-stat {
    line-height: 19px !important;
  }
  .num-1-btn {
    margin-top: 11%;
  }
  .icon-stat {
    margin-right: -13px !important;
    margin-top: -35px !important ;
    font-size: 30px !important;
  }
  .box strong {
    font-size: 17px !important;
  }
  .monthly-stats .ant-card-head-title,
  .daily-stats .ant-card-head-title {
    font-size: 13px !important;
    font-weight: bolder;
  }
  .monthly-stats .ant-card-extra {
    width: 74% !important;
  }
  .monthly-stats .ant-picker {
    padding: 4px 2px 4px !important;
    margin-right: 4px !important;
    width: 38% !important;
  }
  .monthly-stats .ant-picker-input > input {
    font-size: 12px !important;
    font-weight: 500 !important;
  }
  .monthly-stats button.ant-btn.ant-btn-primary {
    height: 27px;
    padding: 4px 9px;
    font-size: 12px;
    font-weight: 600;
  }
  .brand-box-inner {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .full-with {
    width: 100% !important;
  }
  .role-txt {
    font-size: 16px !important;
    margin-top: 7px !important;
  }
  .stats-btn {
    font-size: 12px;
    margin-top: 5px;
    height: 29px;
    margin-right: -11px !important;
    padding: 4px 11px;
  }
}

@media (max-width: 1024px) {
  .left-side {
    margin-top: 48px !important;
    width: 50%;
  }
  .right-side {
    height: 100vh !important;
    width: 55% !important;
  }
  .rightwork p {
    font: normal normal 700 11px/23px Plus Jakarta Sans !important;
    margin-bottom: -30px !important;
  }
  .rightwork .rightlogo img {
    width: 40% !important;
    margin-bottom: -20px !important;
  }
  .stats-font {
    font-size: 19px !important;
    line-height: 20px !important;
  }
  .stats-font-2 {
    line-height: 50px !important;
  }
  .num-1-stat {
    line-height: 20px !important;
  }
  .num-2-btn {
    margin-top: 11% !important ;
  }
  .num-3-btn {
    margin-top: 0% !important;
  }
  .icon-stat {
    margin-right: -13px !important;
    margin-top: -35px !important ;
    font-size: 30px !important;
  }
  .box strong {
    font-size: 15px !important;
  }
  .monthly-stats .ant-card-head-title,
  .daily-stats .ant-card-head-title {
    font-size: 11px !important;
    font-weight: bolder;
  }
  .monthly-stats .ant-card-extra {
    width: 75% !important;
  }
  .monthly-stats .ant-picker {
    padding: 4px 2px 4px !important;
    margin-right: 4px !important;
    width: 35% !important;
  }
  .monthly-stats .ant-picker-input > input {
    font-size: 12px !important;
    font-weight: 500 !important;
  }
  .monthly-stats button.ant-btn.ant-btn-primary {
    height: 27px;
    padding: 4px 9px;
    font-size: 12px;
    font-weight: 600;
  }
  .stats-btn {
    margin-top: -7px;
    padding: 6px;
    height: 27px;
    margin-right: -21px !important;
    font-size: 10px;
    margin-left: 8px;
  }
  .full-with {
    width: 100% !important;
  }
  .role-txt {
    font-size: 15px !important;
    margin-top: 6px;
  }
}

@media (max-width: 992px) {
  .left-side {
    margin-top: 80px !important;
    width: 50%;
  }
  .right-side {
    height: 100vh !important;
    width: 55% !important;
  }
  .rightwork p {
    font: normal normal 300 11px/23px Plus Jakarta Sans !important;
    margin-bottom: -30px !important;
  }
  .rightwork .rightlogo img {
    width: 38% !important;
    margin-bottom: -20px !important;
  }
  .stats-font {
    font-size: 25px !important;
    line-height: 50px !important;
  }
  .stats-font-2 {
    line-height: 50px !important;
  }
  .num-1-stat {
    line-height: 50px !important;
  }
  .num-1-btn {
    margin-top: 1% !important;
  }
  .num-2-btn {
    margin-top: 2% !important ;
  }
  .num-3-btn {
    margin-top: 0% !important;
  }
  .icon-stat {
    margin-right: -13px !important;
    margin-top: -35px !important ;
    font-size: 34px !important;
  }
  .box strong {
    font-size: 18px !important;
  }
  .monthly-stats .ant-card-head-title,
  .daily-stats .ant-card-head-title {
    font-size: 16px !important;
    font-weight: bolder;
    margin-bottom: -10px;
  }
  .monthly-stats .ant-card-extra {
    width: 74% !important;
  }
  .monthly-stats .ant-picker {
    padding: 4px 7px 4px !important;
    margin-right: 13px !important;
    width: 38% !important;
  }
  .monthly-stats .ant-picker-input > input {
    font-size: 12px !important;
    font-weight: 500 !important;
  }
  .monthly-stats button.ant-btn.ant-btn-primary {
    height: 27px;
    padding: 4px 9px;
    font-size: 12px;
    font-weight: 600;
  }
  .monthly-stats .ant-card-head .ant-card-head-wrapper {
    display: flex !important;
    margin-top: 0 !important;
  }
  .monthly-stats .ant-card-body {
    height: 436px !important;
  }
  .stats-btn {
    font-size: 13px;
    padding: 10px;
    justify-content: center;
    height: 36px;
    margin-right: 1px !important;
  }
  .brand-box-inner {
    margin-right: 0px !important;
  }
  .brand-box .ant-card-head .ant-card-head-wrapper {
    text-align: center;
  }
  .ant-col.brand-box-inner h6 b {
    font-size: 13px !important;
  }
  .full-with {
    width: 100% !important;
  }
  .role-txt {
    font-size: 17px !important;
  }
}

@media (max-width: 768px) {
  .left-side {
    width: 100% !important;
  }
  .right-side {
    display: none !important;
  }
  .stats-font {
    font-size: 23px !important;
    line-height: 20px !important;
  }
  .stats-font-2 {
    line-height: 60px !important;
  }
  .num-1-stat {
    line-height: 20px !important;
  }
  .num-1-btn {
    margin-top: 10% !important;
  }
  .num-2-btn {
    margin-top: 10% !important ;
  }
  .num-3-btn {
    margin-top: -3% !important;
  }
  .icon-stat {
    margin-right: -13px !important;
    margin-top: -35px !important ;
    font-size: 34px !important;
  }
  .box strong {
    font-size: 16px !important;
  }
  .monthly-stats .ant-card-head-title,
  .daily-stats .ant-card-head-title {
    font-size: 13px !important;
    font-weight: bolder;
  }
  .monthly-stats .ant-card-extra {
    width: 70% !important;
  }
  .monthly-stats .ant-picker {
    padding: 4px 2px 4px !important;
    margin-right: 4px !important;
    width: 35% !important;
  }
  .monthly-stats .ant-picker-input > input {
    font-size: 12px !important;
    font-weight: 500 !important;
  }
  .monthly-stats button.ant-btn.ant-btn-primary {
    height: 27px;
    padding: 4px 9px;
    font-size: 12px;
    font-weight: 600;
  }
  .role-txt {
    font-size: 16px !important;
  }
}

@media (max-width: 576px) {
  .left-side {
    width: 100% !important;
  }
  .right-side {
    display: none !important;
  }
  .stats-font {
    font-size: 35px !important;
    line-height: 50px !important;
  }
  .stats-font-2 {
    line-height: 60px !important;
  }
  .num-1-stat {
    line-height: 60px !important;
  }
  .num-1-btn {
    margin-top: -2% !important;
  }
  .num-2-btn {
    margin-top: 0% !important ;
  }
  .num-3-btn {
    margin-top: -3% !important;
  }
  .icon-stat {
    margin-right: -3px !important;
    margin-top: -35px !important ;
    font-size: 40px !important;
  }
  .box strong {
    font-size: 18px !important;
  }
  .monthly-stats .ant-card-head-title,
  .daily-stats .ant-card-head-title {
    font-size: 18px !important;
    font-weight: bolder;
  }
  .monthly-stats .ant-card-extra {
    width: 73% !important;
  }
  .monthly-stats .ant-picker {
    padding: 4px 8px 4px !important;
    margin-right: 11px !important;
    width: 40% !important;
  }
  .monthly-stats .ant-picker-input > input {
    font-size: 13px !important;
    font-weight: 500 !important;
  }
  .monthly-stats button.ant-btn.ant-btn-primary {
    height: 29px;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 600;
  }
  .main-row-target {
    justify-content: center;
  }
  .stats-btn {
    margin-left: -2px !important;
  }
  .main-row-target .main-card {
    display: block;
    flex: 0 0 49.666667%;
    max-width: 49.666667%;
  }
  .full-with .ant-card .ant-card-body .ant-row .ant-col.ant-col-8 {
    padding-left: 10px !important;
    padding-right: 10px !important;
    text-align: center !important;
  }
  .role-txt {
    font-size: 17px !important;
    margin-top: -4px !important;
  }
  .ant-transfer.ant-transfer-customize-list {
    justify-content: end !important;
  }
}

@media (max-width: 450px) {
  .stats-font {
    font-size: 35px !important;
    line-height: 50px !important;
  }
  .stats-font-2 {
    line-height: 60px !important;
  }
  .num-1-stat {
    line-height: 60px !important;
  }
  .num-1-btn {
    margin-top: -2% !important;
  }
  .num-2-btn {
    margin-top: 0% !important ;
  }
  .num-3-btn {
    margin-top: -3% !important;
  }
  .icon-stat {
    margin-right: -3px !important;
    margin-top: -35px !important ;
    font-size: 40px !important;
  }
  .box strong {
    font-size: 18px !important;
  }
  .monthly-stats .ant-card-head-title,
  .daily-stats .ant-card-head-title {
    font-size: 14px !important;
    font-weight: bolder;
  }
  .monthly-stats .ant-card-extra {
    width: 73% !important;
  }
  .monthly-stats .ant-picker {
    padding: 4px 8px 4px !important;
    margin-right: 6px !important;
    width: 38% !important;
  }
  .monthly-stats .ant-picker-input > input {
    font-size: 13px !important;
    font-weight: 500 !important;
  }
  .monthly-stats button.ant-btn.ant-btn-primary {
    height: 29px;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 600;
  }
  .main-row-target .main-card {
    flex: 0 0 49.666667%;
    max-width: 49.666667%;
  }
  .role-txt {
    font-size: 15px !important;
  }
  .ant-transfer.ant-transfer-customize-list {
    justify-content: end !important;
  }
  .ant-transfer-customize-list .ant-transfer-list {
    width: 52% !important;
  }
  .justi-center {
    justify-content: center !important;
  }
  .justi-inner {
    display: flex;
    justify-content: center;
    margin-left: 0;
  }
}

@media (max-width: 390px) {
  .left-side {
    width: 100% !important;
  }
  .right-side {
    display: none !important;
  }
  .monthly-stats .ant-card-head-title,
  .daily-stats .ant-card-head-title {
    font-size: 14px !important;
    font-weight: bolder;
  }
  .monthly-stats .ant-card-extra {
    width: 70% !important;
  }
  .monthly-stats .ant-picker {
    padding: 4px 8px 4px !important;
    margin-right: 4px !important;
    width: 35% !important;
  }
  .monthly-stats .ant-picker-input > input {
    font-size: 13px !important;
    font-weight: 500 !important;
  }
  .monthly-stats button.ant-btn.ant-btn-primary {
    height: 29px;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 600;
  }
  .main-row-target .main-card {
    flex: 0 0 49.666667%;
    max-width: 49.666667%;
  }
  .ant-transfer-customize-list .ant-transfer-list {
    width: 100% !important;
  }
  .justi {
    width: 100% !important;
    justify-content: center;
    display: flex;
  }
}

.containers {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: hidden;
}

.left-side {
  padding: 0 80px;
  width: 40%;
  flex-wrap: wrap;
  align-items: center;
  margin: 106px 0 0;
}

.right-side {
  width: 60%;
  height: 100vh;
}
.form-logo {
  padding-bottom: 30px;
}

.login-form button.ant-btn-primary {
  background: transparent linear-gradient(96deg, #3bc29cd7 0%, #00916c 100%) 0% 0%
    no-repeat padding-box;
  width: 100%;
  color: #fff;
  font-size: 22px;
  height: auto !important;
  padding: 18px 79px 17px;
  cursor: pointer;
  transition: 0.5s ease-in-out;
  text-transform: capitalize;
  border-radius: 6px;
  margin-top: 30px;
  font-family: proxy;
  border: 0;
}

.login-form .ant-form-item-control {
  margin-left: 0 !important;
}

.signed-in input[type="checkbox"] {
  margin-right: 10px;
  border: 2px dotted #00f;
}

.signed-in .keep {
  text-align: left;
  font: normal normal 12px/15px Plus Jakarta Sans;
  letter-spacing: 0px;
  color: #acacac;
  opacity: 1;
}

.signed-in a {
  text-align: right;
  font: normal normal 600 12px/15px Plus Jakarta Sans;
  letter-spacing: 0px;
  color: #0499fd;
  opacity: 1;
  text-decoration: none;
}

.form-text h3 {
  text-align: left;
  font: normal normal 800 24px/30px Plus Jakarta Sans;
  letter-spacing: 0px;
  color: #171725;
  opacity: 1;
}

.form-text p {
  text-align: left;
  font: normal normal normal 14px/21px Plus Jakarta Sans;
  letter-spacing: 0px;
  color: #a5a5a5;
  margin-top: 20px;
  margin-bottom: 40px;
}

.form-text p a {
  color: #0499fd;
}

.login-form input#basic_email {
  margin-top: -1px;
}

.login-form .ant-row.ant-form-item-row {
  display: block !important;
}
.login-form input#basic_password {
  border-radius: 10px 0 0 10px;
}

.login-form input#basic_email {
  height: 60px !important;
  margin-bottom: -3px;
  border: 1px solid #ccc;
  width: 100%;
  border-radius: 10px;
}
.login-form .ant-form-item-label {
  text-align: left !important;
  text-align: left;
  font: normal normal 600 12px/15px Plus Jakarta Sans;
  letter-spacing: 0px;
  color: #171725;
  opacity: 1;
  margin-bottom: 20px;
}

.login-form label.ant-form-item-required::after,
.login-form label.ant-form-item-required::before {
  content: "" !important;
}

.login-form .ant-input-affix-wrapper > .ant-input:not(textarea) {
  height: 100%;
  padding-left: 12px;
}

.login-form .ant-input-affix-wrapper {
  padding: 0 !important;
}

.login-form input#basic_email {
  height: 60px !important;
  margin-bottom: -3px;
  border: 1px solid #ccc;
  width: 100%;
  border-radius: 10px;
}
.login-form span.ant-input-password {
  height: 60px !important;
  margin-bottom: -3px;
  border: 1px solid #ccc;
  width: 100%;
  border-radius: 10px;
  background-color: #e8f0fe;
}

.login-form input::placeholder {
  color: #000;
  font: normal normal 600 14px/18px Plus Jakarta Sans;
}
.login-form span.ant-input-suffix {
  padding-right: 11px;
  margin-left: 0 !important;
  font-size: 18px;
}

.signup-details {
  margin-top: -5px;
  width: 100%;
}

.signup-details a {
  font-size: 18px;
  text-transform: capitalize;
  text-decoration: underline;
  color: #000;
  font-weight: 400;
  font-size: 19px;
  font-family: "Alata", sans-serif;
  letter-spacing: 0.1px;
}

.signup-details span {
  font-size: 20px;
  font-family: proxy;
}

.right-side {
  background-image: url("/static/media/Group 7819.5ee70814.svg");
  display: flex;
  justify-content: end;
  align-items: end;
  background-size: cover;
}

.right-side .rightwork {
  margin: 0 50px 50px 0;
  width: 59%;
  display: inline-block;
}

.rightwork p {
  text-align: right;
  font: normal normal normal 14px/23px Plus Jakarta Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-top: 26px;
}

.right-side .rightwork .rightlogo {
  text-align: right;
}

.login-form .ant-form-item-control-input-content img {
  position: absolute;
  z-index: 9;
  top: 21px;
  left: 3%;
}

